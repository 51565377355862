import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { CountriesFacade } from '../facades/countries.facade';
import { filter, take, tap } from 'rxjs';

export function countriesLoaded(): CanActivateFn {
    return () => {
        const countriesFacade = inject(CountriesFacade);

        return countriesFacade.loaded$.pipe(
            tap((loaded) => {
                if (loaded === false) {
                    countriesFacade.getCountries();
                }
            }),
            filter((loaded) => loaded === true),
            take(1),
        );
    };
}
