import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, switchMap, tap, delay, mergeMap, take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { CountriesFacade } from '@app/store/countries';

import {
    selectHeremapsAugosuggestLoading,
    selectHeremapsAugosuggest,
    selectHeremapsAugosuggestLoadingAnything,
    selectHeremapsAugosuggestItems,
} from '../selectors/autosuggest.selector';
import { HereGeocodeModel } from '../models';
import { HeremapsAutosuggestActions } from '../actions/autosuggest.actions';

@Injectable({
    providedIn: 'root',
})
export class HeremapsAutosuggestFacade {
    private store = inject(Store);
    private countriesFacade = inject(CountriesFacade);

    getAutosuggestions(term: string, countries: string[], skipCountryCode: string): void {
        this.store.dispatch(HeremapsAutosuggestActions.getHeremapsAutosuggestAction({ term, countries, skipCountryCode }));
    }

    data$(term: string, skipCountryCode: string) {
        return this.store.select(selectHeremapsAugosuggest(term, skipCountryCode)).pipe(
            switchMap((data) => {
                if (data === null) {
                    return of(data).pipe(
                        delay(200),
                        mergeMap(() => this.countriesFacade.alpha3$.pipe(take(1))),
                        tap((countries) => this.getAutosuggestions(term, countries, skipCountryCode)),
                    );
                }
                return of(data);
            }),
            filter(
                (
                    data,
                ): data is {
                    term: string;
                    skipCountryCode: string;
                    items: HereGeocodeModel[];
                    loading: boolean;
                } => data !== null,
            ),
        );
    }

    loading$ = (term: string, skipCountryCode: string) =>
        this.store.select(selectHeremapsAugosuggestLoading(term, skipCountryCode)).pipe(filter((data) => data !== null));

    get loadingAnything$(): Observable<boolean> {
        return this.store.select(selectHeremapsAugosuggestLoadingAnything);
    }

    get loadingAnything() {
        let loading = false;

        this.store
            .select(selectHeremapsAugosuggestLoadingAnything)
            .pipe(
                take(1),
                tap((value) => (loading = value)),
            )
            .subscribe();

        return loading;
    }

    clear(): void {
        this.store.dispatch(HeremapsAutosuggestActions.clearHeremapsAutosuggestAction());
    }

    /** V2 */
    items$ = (term: string, skipCountryCode: string) =>
        this.store.select(selectHeremapsAugosuggestItems(term, skipCountryCode)).pipe(filter((data): data is HereGeocodeModel[] => data !== null));
}
