import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Time } from '@app/shared/classes';
import { Observable, combineLatest, of } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

@Pipe({
    name: 'dateFormat',
    standalone: true,
    pure: false,
})
export class DateFormatPipe implements PipeTransform {
    private translateService = inject(TranslateService);

    transform(value: string | null, type?: 'digital' | 'day-month' | 'day-mon-year'): Observable<string> {
        if (!value || !Time.stringIsDate(value)) {
            return of('');
        }
        return this.translateService.onLangChange.pipe(
            startWith({ lang: this.translateService.currentLang }),
            switchMap(() => {
                const date = Time.stringToDate(value);

                switch (type) {
                    case 'digital': {
                        const [year, month, day] = value.split('/').map(Number);
                        return of(`${day}.${month}.${year}`);
                    }
                    case 'day-month': {
                        return combineLatest([of(date.getDate()), this.translateService.get(`calendar.month.${date.getMonth()}`)]).pipe(
                            map(([day, month]) => `${day} ${month}`),
                        );
                    }
                    case 'day-mon-year': {
                        return combineLatest([
                            of(date.getDate()),
                            this.translateService.get(`calendar.month.short.${date.getMonth()}`),
                            of(date.getFullYear().toString()),
                        ]).pipe(map(([day, month, year]) => `${day} ${month} ${year}`));
                    }
                    default: {
                        return combineLatest([
                            this.translateService.get(`calendar.weekday.letters.${date.getDay()}`),
                            of(date.getDate()),
                            this.translateService.get(`calendar.month.short.${date.getMonth()}`),
                        ]).pipe(map(([weekday, day, month]) => `${weekday}, ${day} ${month}`));
                    }
                }
            }),
        );
    }
}
