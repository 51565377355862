import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HeremapsLookupState } from '../reducers/lookup.reducer';
import { HEREMAPS_LOOKUP_FEATURE_KEY } from '../keys';

export const selectHeremapsLookupState = createFeatureSelector<HeremapsLookupState>(HEREMAPS_LOOKUP_FEATURE_KEY);

export const selectHeremapsLookup = (id: string) =>
    createSelector(selectHeremapsLookupState, (state: HeremapsLookupState) => {
        return state.items[id] ? state.items[id] : null;
    });

export const selectHeremapsLookupLoading = (id: string) =>
    createSelector(selectHeremapsLookupState, (state: HeremapsLookupState) => {
        return state.items[id] ? state.items[id].loading : null;
    });

export const selectHeremapsLookupLoadingAnything = createSelector(selectHeremapsLookupState, (state: HeremapsLookupState) => {
    return Object.values(state.items).some((item) => item.loading === true);
});
