import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HeremapsNearestState } from '../reducers/nearest.reducer';
import { HEREMAPS_NEAREST_FEATURE_KEY } from '../keys';
import { NearestValue } from '../models';

const selectHeremapsNearestState = createFeatureSelector<HeremapsNearestState>(HEREMAPS_NEAREST_FEATURE_KEY);

export const selectHeremapsNearestItem = (value: NearestValue) =>
    createSelector(selectHeremapsNearestState, (state: HeremapsNearestState) => {
        const index = itemIndex(value, state);
        return index !== -1 && state.data[index].loading === false ? state.data[index].item : null;
    });

export const selectHeremapsNearestEntryExists = (value: NearestValue) =>
    createSelector(selectHeremapsNearestState, (state: HeremapsNearestState) => {
        const index = itemIndex(value, state);
        return index !== -1;
    });

export const selectHeremapsNearestLoading = (value: NearestValue) =>
    createSelector(selectHeremapsNearestState, (state: HeremapsNearestState) => {
        const index = itemIndex(value, state);
        return index !== -1 ? state.data[index].loading : null;
    });

const itemIndex = (value: NearestValue, state: HeremapsNearestState) =>
    state.data.findIndex(
        (item) =>
            item.value.term === value.term &&
            item.value.city === value.city &&
            item.value.countryCode === value.countryCode &&
            item.value.lat === value.lat &&
            item.value.lng === value.lng,
    );
