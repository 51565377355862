import { OrderSegment } from '@app/store/order-segment';
import { CURRENCY } from '@currency';

export class PurchaseGTM {
    event = 'purchase';
    transaction_id: string;
    currency: CURRENCY;
    value: number;
    email: string;
    items: { item_id: string | null; item_name: string; price: number }[];

    constructor(order: OrderSegment) {
        this.transaction_id = order.id;
        this.currency = order.currency;
        this.email = order.email;
        this.value = order.items.map((item) => item.price ?? 0).reduce((sum, price) => sum + price, 0) / 100;
        this.items = order.items.map((item) => ({
            item_id: item.id,
            item_name: item.name,
            price: (item.price ?? 0) / 100,
        }));
    }
}
