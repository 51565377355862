import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Router, RouterModule } from '@angular/router';

import { UserFacade } from '@app/store/user';
import { AccountFacade } from '@app/store/account';
import { ButtonIconComponent } from '@app/shared/components';

@Component({
    selector: 'app-nav-shop',
    templateUrl: './nav-shop.component.html',
    styleUrls: ['./nav-shop.component.scss'],
    standalone: true,
    imports: [CommonModule, TranslateModule, RouterModule, ButtonIconComponent],
})
export class NavShopComponent {
    private readonly userFacade = inject(UserFacade);
    private readonly accountFacade = inject(AccountFacade);
    private readonly router = inject(Router);
    readonly user$ = this.userFacade.user$;

    onOpenLogin() {
        this.router.navigate([{ outlets: { modal: 'account/login' } }]);
    }

    onOpenRegister() {
        this.router.navigate([{ outlets: { modal: 'account/register' } }]);
    }

    logout() {
        this.accountFacade.logout();
    }
}
