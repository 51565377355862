import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HeremapsAutosuggestState } from '../reducers/autosuggest.reducer';
import { HEREMAPS_AUTOSUGGEST_FEATURE_KEY } from '../keys';

const selectHeremapsAutosuggestState = createFeatureSelector<HeremapsAutosuggestState>(HEREMAPS_AUTOSUGGEST_FEATURE_KEY);

export const selectHeremapsAugosuggestItem = (term: string, skipCountryCode: string) =>
    createSelector(selectHeremapsAutosuggestState, (state: HeremapsAutosuggestState) => {
        const index = state.data.findIndex((item) => item.term === term && item.skipCountryCode === skipCountryCode);
        return index !== -1 ? state.data[index].items : null;
    });

export const selectHeremapsAugosuggestLoading = (term: string, skipCountryCode: string) =>
    createSelector(selectHeremapsAutosuggestState, (state: HeremapsAutosuggestState) => {
        const index = state.data.findIndex((item) => item.term === term && item.skipCountryCode === skipCountryCode);
        return index !== -1 ? state.data[index].loading : null;
    });

export const selectHeremapsAugosuggest = (term: string, skipCountryCode: string) =>
    createSelector(selectHeremapsAutosuggestState, (state: HeremapsAutosuggestState) => {
        const index = state.data.findIndex((item) => item.term === term && item.skipCountryCode === skipCountryCode);
        return index !== -1 ? state.data[index] : null;
    });

export const selectHeremapsAugosuggestItems = (term: string, skipCountryCode: string) =>
    createSelector(selectHeremapsAutosuggestState, (state: HeremapsAutosuggestState) => {
        const index = state.data.findIndex((item) => item.term === term && item.skipCountryCode === skipCountryCode);
        return index !== -1 && state.data[index].loading === false ? state.data[index].items : null;
    });

export const selectHeremapsAugosuggestLoadingAnything = createSelector(selectHeremapsAutosuggestState, (state: HeremapsAutosuggestState) => {
    return state.data.some((item) => item.loading === true);
});
