import { createActionGroup, props, emptyProps } from '@ngrx/store';
import { HereGeocodeModel } from '../models';

export const HeremapsLookupActions = createActionGroup({
    source: 'Heremaps Lookup',
    events: {
        'Get Heremaps Lookup Action': props<{ id: string }>(),
        'Get Heremaps Lookup Success Action': props<{ id: string; payload: HereGeocodeModel }>(),
        'Get Heremaps Lookup Error Action': props<{ id: string }>(),
        'Clear Heremaps Lookup Action': emptyProps(),
    },
});
