import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApiResponse } from '@app/shared/models';
import { map } from 'rxjs';
import { Message, MessageDto } from '../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private httpClient = inject(HttpClient);

    messages() {
        return this.httpClient
            .addServer()
            .skipErrorHandler()
            .skipToken()
            .skipRefreshToken()
            .get<ApiResponse<MessageDto[]>>(`api/notification`)
            .pipe(map((response) => response.data.map((item) => new Message(item))));
    }
}
