import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { COUNTRIES_FEATURE_KEY } from './keys';
import { countriesReducer } from './reducers/countries.reducer';
import { CountriesEffects } from './effects/countries.effects';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(COUNTRIES_FEATURE_KEY, countriesReducer), EffectsModule.forFeature([CountriesEffects])],
})
export class CountriesStoreModule {}
