import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Message } from '../models';

export const tickerActions = createActionGroup({
    source: 'Ticker',
    events: {
        Get: emptyProps(),
        'Get Success': props<{ value: Message[] }>(),
        'Get Error': emptyProps(),
        Clear: emptyProps(),
    },
});
