import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { of, catchError, finalize, map, mergeMap, tap, filter, switchMap, take, debounceTime, distinctUntilChanged, forkJoin, delay } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatLatestFrom } from '@ngrx/operators';
import { AlertsActionsService, AlertsService } from '@app/shared/alerts';
import { LoaderFacade } from '@app/store/loader';
import { HttpService } from '../services/http.service';
import { orderActions } from '../actions/order.actions';
import { discountCodeActions } from '@app/store/discount-code/actions/discount-code.actions';
import { selectOrder, selectUpdatesPending, selectUpdating } from '../selectors/order.selector';
import { Error, Order, OrderUpdateValue } from '../models';
import { GtmTrackingService } from '@app/gtm';

@Injectable()
export class OrderEffects {
    private readonly actions = inject(Actions);
    private readonly httpService = inject(HttpService);
    private readonly loaderFacade = inject(LoaderFacade);
    private readonly document = inject(DOCUMENT);
    // FIXME?
    readonly store = inject(Store);
    readonly alertsService = inject(AlertsService);
    readonly alertsActionsService = inject(AlertsActionsService);
    readonly gtmTrackingService = inject(GtmTrackingService);
    readonly router = inject(Router);

    createOrder$ = createEffect(() => {
        return this.actions.pipe(
            ofType(orderActions.createOrder),
            mergeMap((action) => {
                this.loaderFacade.add('order-create-draft');
                return this.httpService.createOrder(action.value, action.transitFrom, action.transitTo).pipe(
                    map((response) => orderActions.createOrderSuccess({ payload: response })),
                    catchError((error: Error) => of(orderActions.createOrderError({ error }))),
                    finalize(() => this.loaderFacade.remove('order-create-draft')),
                );
            }),
        );
    });

    createOrderError$ = createEffect(
        () => {
            return this.actions.pipe(
                ofType(orderActions.createOrderError),
                tap((payload) => {
                    switch (payload.error.type) {
                        case 'time-limit-reached':
                            this.alertsService.show('alert.error.booking-order-excceeded', 'warning', true);
                            break;
                        case 'passenger-count-limit-reached':
                            this.alertsService.show('alert.error.booking-order-limit-excceeded', 'warning', true);
                            break;
                        default:
                            this.alertsService.show('alert.error.generic-with-contact', 'danger', true);
                            break;
                    }
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    getOrder$ = createEffect(() => {
        return this.actions.pipe(
            ofType(orderActions.getOrder, discountCodeActions.attachSuccess, discountCodeActions.detachSuccess),
            mergeMap((action) => {
                this.loaderFacade.add('order-get');
                return this.httpService.getOrder(action.id).pipe(
                    map((payload) => orderActions.getOrderSuccess({ payload })),
                    catchError((error: HttpErrorResponse) => of(orderActions.getOrderError({ error }))),
                    finalize(() => this.loaderFacade.remove('order-get')),
                );
            }),
        );
    });

    getOrderByToken$ = createEffect(() => {
        return this.actions.pipe(
            ofType(orderActions.getOrderByToken),
            mergeMap((action) => {
                this.loaderFacade.add('order-get');
                return this.httpService.getOrderByToken(action.id, action.token).pipe(
                    map((payload) => orderActions.getOrderSuccess({ payload })),
                    catchError((response: HttpErrorResponse) => of(orderActions.getOrderByTokenError({ response }))),
                    finalize(() => this.loaderFacade.remove('order-get')),
                );
            }),
        );
    });

    updateOrder$ = createEffect(() => {
        return this.actions.pipe(
            ofType(orderActions.updateOrder),
            map(() => orderActions.updateOrderCheck()),
        );
    });

    updateOrderCheck$ = createEffect(() => {
        return this.actions.pipe(
            ofType(orderActions.updateOrderCheck, orderActions.updateOrderSuccess, orderActions.updateOrderError),
            concatLatestFrom(() => [this.store.select(selectUpdating), this.store.select(selectUpdatesPending)]),
            filter(([, updating, value]) => updating === false && value !== null),
            map(() => orderActions.updateOrderRun()),
        );
    });

    updateOrderRun$ = createEffect(() => {
        return this.actions.pipe(
            ofType(orderActions.updateOrderRun),
            concatLatestFrom(() => [this.store.select(selectOrder), this.store.select(selectUpdatesPending)]),
            mergeMap((data) => {
                const order = <Order>data[1];
                const value = <OrderUpdateValue>data[2];

                return this.httpService.updateOrder(order.id, value, order).pipe(
                    map((payload) => orderActions.updateOrderSuccess({ payload })),
                    catchError(() => of(orderActions.updateOrderError())),
                );
            }),
        );
    });

    updateOrderGTM$ = createEffect(
        () => {
            return this.actions.pipe(
                ofType(orderActions.updateOrderSuccess),
                map((action) => action.payload),
                tap((order) => this.gtmTrackingService.cartSummaryUpdate(order)),
            );
        },
        { dispatch: false },
    );

    updateOrderClear$ = createEffect(() => {
        return this.actions.pipe(
            ofType(orderActions.updateOrderRun),
            map(() => orderActions.updateOrderClear()),
        );
    });

    submitOrder$ = createEffect(() => {
        return this.actions.pipe(
            ofType(orderActions.submitOrder),
            switchMap((action) =>
                forkJoin([
                    of(action).pipe(take(1)),
                    this.store.select(selectUpdating).pipe(
                        debounceTime(10),
                        distinctUntilChanged(),
                        filter((value) => value === false),
                        take(1),
                    ),
                ]),
            ),
            mergeMap(([action]) => {
                if (action.transactionId !== null) {
                    return this.httpService.submitOrderTransaction(action.paymentId, action.transactionId).pipe(
                        map((payload) => orderActions.submitOrderSuccess({ payload })),
                        catchError((error: Error) => of(orderActions.submitOrderError({ error }))),
                    );
                }

                return this.httpService.submitOrder(action.orderId, action.paymentId).pipe(
                    map((payload) => orderActions.submitOrderSuccess({ payload })),
                    catchError((error: Error) => of(orderActions.submitOrderError({ error }))),
                );
            }),
        );
    });

    submitOrderError$ = createEffect(
        () => {
            return this.actions.pipe(
                ofType(orderActions.submitOrderError),
                tap((payload) => {
                    // #FIXME
                    // case 422:
                    // if (error && error.errors) {
                    //     error.errors?.forEach(({ title }) => this.alertsService.show(title, 'danger', false));
                    // }
                    // break;
                    switch (payload.error.type) {
                        case 'time-limit-reached':
                            this.alertsService.show('alert.error.booking-order-excceeded', 'warning', true);
                            break;
                        case 'passenger-count-limit-reached':
                            this.alertsService.show('alert.error.booking-order-limit-excceeded', 'warning', true);
                            break;
                        case 'payment-expired':
                            this.alertsService.show('alert.error.paymnet-overdue', 'warning', true);
                            break;
                        case 'order-already-confirmed':
                            this.alertsService.show('booking.confirmation.status.paid', 'warning', true);
                            break;
                        default:
                            this.alertsService.show('alert.error.generic-with-contact', 'danger', true);
                            break;
                    }
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    submitOrderGTM$ = createEffect(
        () => {
            return this.actions.pipe(
                ofType(orderActions.submitOrderSuccess),
                concatLatestFrom(() => this.store.select(selectOrder)),
                map(([, order]) => order),
                filter((order) => order !== null),
                tap((order) => this.gtmTrackingService.beginCheckout(order)),
            );
        },
        { dispatch: false },
    );

    submitOrderRedirect$ = createEffect(
        () => {
            return this.actions.pipe(
                ofType(orderActions.submitOrderSuccess),
                tap(() => this.loaderFacade.add('payment-redirect')),
                delay(500),
                tap((action) => (this.document.location.href = action.payload.redirectUrl)),
            );
        },
        { dispatch: false },
    );
}
