import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HeremapsClosestState } from '../reducers/closest.reducer';
import { HEREMAPS_CLOSEST_FEATURE_KEY } from '../keys';

const selectHeremapsClosestState = createFeatureSelector<HeremapsClosestState>(HEREMAPS_CLOSEST_FEATURE_KEY);

export const selectHeremapsClosestItem = (term: string, city: string, countryCode: string, lat: number, lng: number) =>
    createSelector(selectHeremapsClosestState, (state: HeremapsClosestState) => {
        const index = state.data.findIndex(
            (item) => item.term === term && item.city === city && item.countryCode === countryCode && item.lat === lat && item.lng === lng,
        );
        return index !== -1 ? state.data[index].item : null;
    });

export const selectHeremapsClosestLoading = (term: string, city: string, countryCode: string, lat: number, lng: number) =>
    createSelector(selectHeremapsClosestState, (state: HeremapsClosestState) => {
        const index = state.data.findIndex(
            (item) => item.term === term && item.city === city && item.countryCode === countryCode && item.lat === lat && item.lng === lng,
        );
        return index !== -1 ? state.data[index].loading : null;
    });

export const selectHeremapsClosest = (term: string, city: string, countryCode: string, lat: number, lng: number) =>
    createSelector(selectHeremapsClosestState, (state: HeremapsClosestState) => {
        const index = state.data.findIndex(
            (item) => item.term === term && item.city === city && item.countryCode === countryCode && item.lat === lat && item.lng === lng,
        );
        return index !== -1 ? state.data[index] : null;
    });

export const selectHeremapsClosestLoadingAnything = createSelector(selectHeremapsClosestState, (state: HeremapsClosestState) => {
    return state.data.some((item) => item.loading === true);
});
