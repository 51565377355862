import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { selectLoaded, selectLoading, selectMessages } from '../selectors/ticker.selector';
import { tickerActions } from '../actions/ticker.actions';

@Injectable({
    providedIn: 'root',
})
export class TickerFacade {
    private store = inject(Store);

    readonly messages$ = this.store.select(selectMessages).pipe(filter((messages) => messages !== null));
    readonly loaded$ = this.store.select(selectLoaded);
    readonly loading$ = this.store.select(selectLoading);

    clear(): void {
        this.store.dispatch(tickerActions.clear());
    }
}
