import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MissingTranslationHandlerTest, I18nModule, TranslateLoaderStatic } from './i18n';
import { OrderStoreModule } from './store/order';

import { UserStoreModule } from './store/user';
import { environment } from '../environments/environment';
import { HttpService } from './core/http';
import { AppComponent } from './app.component';
import { LoaderModule } from './store/loader';
import { CountriesStoreModule } from './store/countries';
import { MaintenanceModule } from './maintenance';
import { TickerStoreModule } from './store/ticker';
// v2
import { ShellModule } from './shell/shell.module';
import { AccountModule } from './account';
import { MobileMenuModule } from './mobile-menu/mobile-menu.module';

@NgModule({
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ShellModule,
        // common
        LoaderModule,
        UserStoreModule,
        CountriesStoreModule,
        OrderStoreModule,
        MaintenanceModule,
        TickerStoreModule,
        I18nModule,
        AccountModule,
        MobileMenuModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: () => {
                    return new TranslateLoaderStatic();
                },
                deps: [],
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useFactory: () => {
                    return new MissingTranslationHandlerTest();
                },
                deps: [],
            },
            defaultLanguage: environment.defaultLanguage,
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.sw,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
        StoreModule.forRoot({}, {}),
        EffectsModule.forRoot([]),
        StoreDevtoolsModule.instrument({
            // Devtools mmust be imported after StoreModule
            maxAge: 25,
            logOnly: environment.production,
            connectInZone: true,
        }),
        AppRoutingModule,
        GoogleTagManagerModule.forRoot({
            id: environment.integrations.google.tagManager.id,
        }),
    ],
    providers: [
        provideHttpClient(withInterceptorsFromDi()),
        {
            provide: HttpClient,
            useClass: HttpService,
        },
    ],
})
export class AppModule {}
