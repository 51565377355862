<app-box type="back">
    <h4>{{ 'mobile.menu.item.language.title' | translate }}</h4>
    <h6>{{ 'mobile.menu.item.language.select-language' | translate }}</h6>

    <ul>
        @for (language of languages; track language.key) {
            <li>
                <button type="button" (click)="onLanguageClick(language.key)" [class.active]="language.key === lang">
                    <img src="assets/svgs-optimized-flags/{{ flag(language.key) }}.svg" />

                    <span>{{ 'language.select.' + language.key | translate }}</span>

                    <div class="tw-bg-primary-700 tw-rounded-full tw-p-1 tw-ml-auto mark">
                        <app-icon [size]="12" icon="mark-bold" color="white" />
                    </div>
                </button>
            </li>
        }
    </ul>

    <h6>{{ 'mobile.menu.item.language.select-currency' | translate }}</h6>

    <ul>
        @for (currency of currencies; track $index) {
            <li>
                <button type="button" (click)="onCurrencyClick(currency)" [class.active]="currency === (currency$ | async)">
                    <span>{{ currency }}</span>

                    <div class="tw-bg-primary-700 tw-rounded-full tw-p-1 tw-ml-auto mark">
                        <app-icon [size]="12" icon="mark-bold" color="white" />
                    </div>
                </button>
            </li>
        }
    </ul>
</app-box>
