import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private httpClient = inject(HttpClient);

    refresh(): Observable<Request> {
        return this.httpClient.addServer().skipToken().skipRefreshToken().post<Request>('api/auth/refresh', {}, { withCredentials: true }).pipe(share());
    }
}
