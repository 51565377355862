<button
    #focusEl
    type="button"
    class="tw-relativ input"
    [class.active]="(focus$ | async) === true"
    [class.filled]="(value$ | async) !== ''"
    [class.disabled]="fc.disabled"
    (focusin)="onFocusIn()"
    (focusout)="onFocusOut()"
    [attr.control-error]="fc.invalid && scrollToError"
>
    <div class="value">{{ (value$ | async) || '&nbsp;' }}</div>
    <input type="hidden" [formControl]="fc" />
    <div class="label" *ngIf="label">
        <span>{{ label }}</span>
    </div>
    <app-icon icon="chevron-up-filled" color="gray-600" [size]="20" [class.tw-rotate-180]="(focus$ | async) === false" />
    @if (focus$ | async) {
        <app-form-list @Grow [value]="fc.getRawValue()" [items]="items" (selectValueEvent)="onSelectValueEvent($event)" />
    }
</button>

<div #blurEl tabindex="-1"></div>

@if (fc.touched && fc.invalid && fc.errors !== null) {
    <div @Grow>
        <app-form-errors class="tw-pt-2" [errors]="fc.errors" />
    </div>
}
