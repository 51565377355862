import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { environment } from '@env/environment';
import { ApiResponse } from '@app/shared/models';
import { COUNTRY } from '@app/shared/classes';
import { Country } from '../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private httpClient = inject(HttpClient);

    countries(): Observable<Country[]> {
        return this.httpClient
            .addPanel()
            .get<ApiResponse<Country[]>>(`api/v1.0/sectors/getCountries`)
            .pipe(
                map((response) => Object.values(response.data)),
                catchError(() => {
                    return of(
                        environment.integrations.hereMaps.autosuggest.countries.map((countryCode: string) => ({
                            id: COUNTRY.con3to2(countryCode),
                            name: COUNTRY.con3to2(countryCode),
                            sectors: 1,
                            api: true,
                            allowed_arrivals: [],
                        })),
                    );
                }),
            );
    }
}
