import { Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent, ButtonIconComponent } from '@app/shared/components';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

@Component({
    selector: 'app-box',
    templateUrl: './box.component.html',
    styleUrls: ['./box.component.scss'],
    standalone: true,
    imports: [CommonModule, RouterModule, TranslateModule, ButtonComponent, ButtonIconComponent],
})
export class BoxComponent {
    private router = inject(Router);
    activatedRoute = inject(ActivatedRoute);

    @Input() type: 'logo' | 'back' = 'logo';
    @Input() title?: string;

    onCloseClick() {
        this.router.navigate([{ outlets: { mobile: null } }]);
    }
}
