<div class="tw-relative" [attr.control-error]="fc.invalid && scrollToError">
    <input
        [ngClass]="!noIconInput ? 'tw-pr-10' : 'tw-pr-4'"
        [type]="(preview$ | async) ? 'text' : type"
        placeholder=" "
        [formControl]="fc"
        [attr.autocomplete]="autocomplete ?? null"
    />
    <div class="label" *ngIf="label">
        <span>{{ label }}</span>
    </div>
    @if (noIconInput === false) {
        @if (preview) {
            @if ((preview$ | async) === true) {
                <app-icon (click)="onPreviewClick()" icon="eye-slash" [size]="20" class="preview" />
            } @else {
                <app-icon (click)="onPreviewClick()" icon="eye" [size]="20" class="preview" />
            }
        } @else {
            <app-icon icon="cricle-mark" color="primary-700" [size]="20" />
        }
    }
</div>
@if (fc.touched && fc.invalid && fc.errors !== null) {
    <div @Grow>
        <app-form-errors class="tw-pt-2" [errors]="fc.errors" />
    </div>
}
