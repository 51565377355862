import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { countriesActions } from '../actions/countries.actions';
import { HttpService } from '../services/http.service';

@Injectable()
export class CountriesEffects {
    private actions$ = inject(Actions);
    private httpService = inject(HttpService);

    countries$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(countriesActions.getCountries),
            mergeMap(() => {
                return this.httpService.countries().pipe(
                    map((payload) => countriesActions.getCountriesSuccess({ payload })),
                    catchError(() => of(countriesActions.getCountriesError())),
                );
            }),
        );
    });
}
