import { MessageDto } from './message.dto';

export class Message {
    id: string;
    type: string;
    content: string;
    active: boolean;
    activeFrom: string | null;
    activeUntil: string | null;
    created: string | null;
    updated: string | null;

    constructor(data: MessageDto) {
        this.id = data.id;
        this.type = data.type;
        this.content = data.content;
        this.active = data.active;
        this.activeFrom = data.activeFrom;
        this.activeUntil = data.activeUntil;
        this.created = data.created;
        this.updated = data.updated;
    }
}
