import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../shared/components';
import { NavComponent, AuthComponent, TickerComponent, NavShopComponent, NavFooterComponent, NavSocialComponent, SettingsDropdownsComponent } from './shared';
import { FooterComponent } from './components/footer/footer.component';

import { ShellComponent } from './components/shell/shell.component';
import { HeaderComponent } from './components/header/header.component';
import { AlertsModule } from '@app/shared/alerts';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
        IconComponent,
        NavComponent,
        NavShopComponent,
        NavFooterComponent,
        NavSocialComponent,
        AuthComponent,
        TickerComponent,
        SettingsDropdownsComponent,
        AlertsModule,
    ],
    declarations: [ShellComponent, HeaderComponent, FooterComponent],
})
export class ShellModule {}
