import { CommonModule } from '@angular/common';
import { Component, ElementRef, inject, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { shareReplay, Subscription, tap } from 'rxjs';
import { DateFormatPipe } from '@app/shared/pipes';
import { IconComponent } from '@app/shared/components';
import { FocusService } from './services/focus.service';
import { FormCalendarPickerComponent } from './components/picker/picker.component';
import { FormErrorsComponent } from '@app/shared/forms';
import { disabler$, Grow } from '@app/shared/animations';

@Component({
    selector: 'app-form-calendar',
    styleUrls: ['./form-calendar.component.scss'],
    templateUrl: './form-calendar.component.html',
    standalone: true,
    providers: [FocusService],
    animations: [Grow],
    imports: [CommonModule, ReactiveFormsModule, IconComponent, DateFormatPipe, FormCalendarPickerComponent, FormErrorsComponent],
})
export class FormCalendarComponent implements OnInit, OnDestroy {
    private readonly controlContainer = inject(ControlContainer);
    private readonly renderer2 = inject(Renderer2);
    private readonly elementRef = inject(ElementRef);
    private readonly focusService = inject(FocusService);

    @Input({ required: true }) controlName!: string;
    @Input({ required: true }) label!: string;
    @Input({ required: true }) placeholder!: string;
    @Input() startDate: string | null = null;
    @Input() endDate: string | null = null;
    @Input() timeType?: 'digital' | 'day-month' | 'day-mon-year';
    @ViewChild('focusEl') focusEl!: ElementRef;

    readonly disabled$ = disabler$.pipe(shareReplay());

    fc!: FormControl<string>;

    readonly focus$ = this.focusService.focus$;

    private subscriptions$ = new Subscription();

    ngOnInit() {
        this.fc = <FormControl<string>>(<FormGroup>this.controlContainer.control).get(this.controlName);

        this.subscriptions$.add(this.focusService.selectValue$.pipe(tap((value) => this.patchValue(value))).subscribe());
        this.subscriptions$.add(this.focusService.close$.pipe(tap(() => this.blur())).subscribe());
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    onFocusIn() {
        this.focusService._focus$.next(true);
        this.renderer2.addClass(this.elementRef.nativeElement, 'active');
    }

    onFocusOut() {
        this.focusService._focus$.next(false);
        this.renderer2.removeClass(this.elementRef.nativeElement, 'active');
    }

    private blur() {
        this.focusEl.nativeElement.focus();
        this.focusEl.nativeElement.blur();
    }

    private patchValue(value: string) {
        this.fc.setValue(value);
        this.fc.markAsTouched();
        this.focusEl.nativeElement.focus();
        this.focusEl.nativeElement.blur();
    }
}
