import { createReducer, on } from '@ngrx/store';

import { HeremapsNearestActions } from '../actions/nearest.actions';
import { HereGeocodeModel, NearestValue } from '../models';

export interface HeremapsNearestState {
    data: HeremapsNearestData[];
}

interface HeremapsNearestData {
    loading: boolean;
    value: NearestValue;
    item: HereGeocodeModel | null;
}

export const initialState: HeremapsNearestState = {
    data: [],
};

export const heremapsNearestReducer = createReducer(
    initialState,
    on(HeremapsNearestActions.getNearestInitAction, (state, action): HeremapsNearestState => {
        const data = [...state.data];
        const index = itemIndex(action.value, state.data);

        if (index === -1) {
            data.push({
                loading: true,
                item: null,
                value: action.value,
            });
        }

        return { data };
    }),
    on(HeremapsNearestActions.getNearestSuccessAction, (state, action): HeremapsNearestState => {
        const data = [...state.data];
        const index = itemIndex(action.value, state.data);

        data[index] = {
            ...data[index],
            item: action.payload,
            loading: false,
        };

        return { data };
    }),
    on(HeremapsNearestActions.getNearestErrorAction, (state, action): HeremapsNearestState => {
        const data = [...state.data];
        const index = itemIndex(action.value, state.data);

        data[index] = {
            ...data[index],
            loading: false,
        };

        return { data };
    }),
    on(
        HeremapsNearestActions.clearAction,
        (): HeremapsNearestState => ({
            ...initialState,
        }),
    ),
);

const itemIndex = (value: NearestValue, data: HeremapsNearestData[]) =>
    data.findIndex(
        (item) =>
            item.value.term === value.term &&
            item.value.city === value.city &&
            item.value.countryCode === value.countryCode &&
            item.value.lat === value.lat &&
            item.value.lng === value.lng,
    );
