import { Component } from '@angular/core';
import { environment } from '@env/environment';
import { build } from 'src/build';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
    readonly version = <{ tag: string; time: string }>build;
    readonly environment = environment;
}
