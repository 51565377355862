import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';
import { StorageService } from '@app/shared/services';
import { I18nHtmlLangService } from './html-lang.service';

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    private translateService = inject(TranslateService);
    private storageService = inject(StorageService);
    private i18nHtmlLangService = inject(I18nHtmlLangService);

    readonly language$ = new BehaviorSubject<string>('');
    readonly changed$ = new Subject<string>();

    init() {
        this.translateService.addLangs(environment.supportedLanguages.map((lang) => lang.key));
        this.translateService.setDefaultLang(environment.defaultLanguage);
        const lang = <string>this.storageService.getItem('language');
        if (lang && environment.supportedLanguages.map((lang) => lang.key).includes(lang)) {
            this.translateService.use(lang);
            this.language$.next(lang);
            this.changed$.next(this.get());
        } else {
            this.language$.next(this.get());
            this.changed$.next(this.get());
        }
    }

    set(lang: string): void {
        this.translateService.use(lang);
        this.i18nHtmlLangService.setLang(lang);
        this.storageService.setItem('language', lang);
        this.language$.next(lang);
        this.changed$.next(this.get());
    }

    get(): string {
        if (!this.translateService.currentLang) {
            this.translateService.use(environment.defaultLanguage);
        }
        return this.translateService.currentLang;
    }
}
