import { createReducer, on } from '@ngrx/store';

import { HeremapsAutosuggestActions } from '../actions/autosuggest.actions';
import { HereGeocodeModel } from '../models';

export interface HeremapsAutosuggestState {
    data: {
        term: string;
        skipCountryCode: string;
        items: HereGeocodeModel[];
        loading: boolean;
    }[];
}

export const initialState: HeremapsAutosuggestState = {
    data: [],
};

export const heremapsAutosuggestReducer = createReducer(
    initialState,
    on(HeremapsAutosuggestActions.getHeremapsAutosuggestAction, (state, action): HeremapsAutosuggestState => {
        if (state.data.findIndex((item) => item.term === action.term && item.skipCountryCode === action.skipCountryCode) !== -1) {
            return { ...state };
        }
        return {
            ...state,
            data: [
                ...state.data,
                {
                    term: action.term,
                    skipCountryCode: action.skipCountryCode,
                    items: [],
                    loading: true,
                },
            ],
        };
    }),
    on(HeremapsAutosuggestActions.getHeremapsAutosuggestSuccessAction, (state, action): HeremapsAutosuggestState => {
        const data = [...state.data];
        const itemIndex = data.findIndex((item) => item.term === action.term && item.skipCountryCode === action.skipCountryCode);
        data[itemIndex] = {
            ...data[itemIndex],
            items: action.payload,
            loading: false,
        };
        return { data };
    }),
    on(HeremapsAutosuggestActions.getHeremapsAutosuggestErrorAction, (state, action): HeremapsAutosuggestState => {
        const data = [...state.data];
        const itemIndex = data.findIndex((item) => item.term === action.term && item.skipCountryCode === action.skipCountryCode);
        data[itemIndex] = {
            ...data[itemIndex],
            loading: false,
        };
        return { data };
    }),
    on(
        HeremapsAutosuggestActions.clearHeremapsAutosuggestAction,
        (state): HeremapsAutosuggestState => ({
            ...state,
            ...initialState,
        }),
    ),
);
