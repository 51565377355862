import { Inject, Injectable, InjectionToken, Injector, Optional } from '@angular/core';
import { HttpClient, HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
    ApiPrefixHeremapsLookupInterceptor,
    ApiPrefixHeremapsRevGeocodeInterceptor,
    ApiPrefixPanelInterceptor,
    ApiPrefixServerInterceptor,
    ApiPrefixAssetsInterceptor,
    ApiTokenInterceptor,
    ApiErrorInterceptor,
    ApiTokenRefreshInterceptor,
    ApiTokenRefreshSilentInterceptor,
    ApiTemplateInterceptor,
    ApiPrefixHeremapsAutosuggestInterceptor,
    ApiPrefixMaitenanceInterceptor,
    ApiCacheInterceptor,
} from '../interceptors';
import { ApiMaintenanceErrorHandlerInterceptor } from '@app/maintenance';

// HttpClient is declared in a re-exported module, so we have to extend the original module to make it work properly
// (see https://github.com/Microsoft/TypeScript/issues/13897)
declare module '@angular/common/http' {
    // Augment HttpClient with the added configuration methods from HttpService, to allow in-place replacement of
    // HttpClient with HttpService using dependency injection
    export interface HttpClient {
        /**
         * Skips default error handler for this request.
         * @return {HttpClient} The new instance.
         */
        skipErrorHandler(): HttpClient;

        /**
         * Do not use API prefix for this request.
         * @return {HttpClient} The new instance.
         */
        skipApiServerPrefix(): HttpClient;

        /**
         * Skips Token
         * @return {HttpClient} The new instance.
         */
        skipToken(): HttpClient;

        /**
         * Skips Refresh Token
         * @return {HttpClient} The new instance.
         */
        skipRefreshToken(): HttpClient;

        /**
         * Add Refresh Token Silent
         * @return {HttpClient} The new instance.
         */
        addRefreshTokenSilent(): HttpClient;

        /**
         * Include Server interceptors
         * @return {HttpClient} The new instance.
         */
        addServer(): HttpClient;

        /**
         * Include Panel interceptors
         * @return {HttpClient} The new instance.
         */
        addPanel(): HttpClient;

        /**
         * Include Maintenance interceptors
         * @return {HttpClient} The new instance.
         */
        addMaintenance(): HttpClient;

        /**
         * Include Heremaps - Autousggest interceptors
         * @return {HttpClient} The new instance.
         */
        addHeremapsAutosuggest(): HttpClient;

        /**
         * Include Heremaps - Rev Geocode interceptors
         * @return {HttpClient} The new instance.
         */
        addHeremapsRevGeocode(): HttpClient;

        /**
         * Include Heremaps - Lookup interceptors
         * @return {HttpClient} The new instance.
         */
        addHeremapsLookup(): HttpClient;

        /**
         * Maintenance Intercepter
         * @return {HttpClient} The new instance.
         */
        skipMaintenanceInterceptor(): HttpClient;

        /**
         * Add Cache Interceptor
         * @return {HttpClient} The new instance.
         */
        addCacheInterceptor(): HttpClient;

        /**
         * Add Assets Interceptor
         * @return {HttpClient} The new instance.
         */
        addApiAssetsInterceptor(): HttpClient;

        /**
         * AddCustomInterceptor
         * @return {HttpClient} The new instance.
         */
        addCustomInterceptor(): HttpClient;
    }
}

// From @angular/common/http/src/interceptor: allows to chain interceptors
class HttpInterceptorHandler implements HttpHandler {
    constructor(
        private next: HttpHandler,
        private interceptor: HttpInterceptor,
    ) {}

    handle(request: HttpRequest<unknown>): Observable<HttpEvent<unknown>> {
        return this.interceptor.intercept(request, this.next);
    }
}

/**
 * Allows to override default dynamic interceptors that can be disabled with the HttpService extension.
 * Except for very specific needs, you should better configure these interceptors directly in the constructor below
 * for better readability.
 *
 * For static interceptors that should always be enabled (like ApiPrefixInterceptor), use the standard
 * HTTP_INTERCEPTORS token.
 */
export const HTTP_DYNAMIC_INTERCEPTORS = new InjectionToken<HttpInterceptor>('HTTP_DYNAMIC_INTERCEPTORS');

/**
 * Extends HttpClient with per request configuration using dynamic interceptors.
 */
@Injectable({
    providedIn: 'root',
})
export class HttpService extends HttpClient {
    constructor(
        private httpHandler: HttpHandler,
        private injector: Injector,
        @Optional() @Inject(HTTP_DYNAMIC_INTERCEPTORS) private interceptors: HttpInterceptor[] = [],
    ) {
        super(httpHandler);
        if (!this.interceptors) {
            this.interceptors = [];
        }
    }

    override skipErrorHandler(): HttpClient {
        return this.removeInterceptor(ApiErrorInterceptor);
    }

    override skipMaintenanceInterceptor(): HttpClient {
        return this.removeInterceptor(ApiMaintenanceErrorHandlerInterceptor);
    }

    override skipToken(): HttpClient {
        return this.removeInterceptor(ApiTokenInterceptor);
    }

    override skipApiServerPrefix(): HttpClient {
        return this.removeInterceptor(ApiPrefixServerInterceptor);
    }

    override skipRefreshToken(): HttpClient {
        return this.removeInterceptor(ApiTokenRefreshInterceptor);
    }

    override addCacheInterceptor(): HttpClient {
        return this.addInterceptor(ApiCacheInterceptor);
    }

    override addApiAssetsInterceptor(): HttpClient {
        return this.addInterceptor(ApiPrefixAssetsInterceptor);
    }

    override addCustomInterceptor(): HttpClient {
        return this.addInterceptor(ApiTemplateInterceptor);
    }

    override addServer(): HttpClient {
        return this.addInterceptor(ApiPrefixServerInterceptor)
            .addInterceptor(ApiTokenInterceptor)
            .addInterceptor(ApiTokenRefreshInterceptor)
            .addInterceptor(ApiErrorInterceptor)
            .addInterceptor(ApiMaintenanceErrorHandlerInterceptor);
    }

    override addPanel(): HttpClient {
        return this.addInterceptor(ApiPrefixPanelInterceptor).addInterceptor(ApiErrorInterceptor);
    }

    override addMaintenance(): HttpClient {
        return this.addInterceptor(ApiPrefixMaitenanceInterceptor);
    }

    override addRefreshTokenSilent(): HttpClient {
        return this.addInterceptor(ApiTokenRefreshSilentInterceptor);
    }

    override addHeremapsAutosuggest(): HttpClient {
        return this.addInterceptor(ApiPrefixHeremapsAutosuggestInterceptor);
    }

    override addHeremapsRevGeocode(): HttpClient {
        return this.addInterceptor(ApiPrefixHeremapsRevGeocodeInterceptor);
    }

    override addHeremapsLookup(): HttpClient {
        return this.addInterceptor(ApiPrefixHeremapsLookupInterceptor);
    }

    // Override the original method to wire interceptors when triggering the request.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    override request(method?: any, url?: any, options?: any): any {
        const handler = this.interceptors.reduceRight((next, interceptor) => new HttpInterceptorHandler(next, interceptor), this.httpHandler);

        return new HttpClient(handler).request(<string>method, <string>url, options);
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
    private removeInterceptor(interceptorType: Function): HttpService {
        return new HttpService(
            this.httpHandler,
            this.injector,
            this.interceptors.filter((i) => !(i instanceof interceptorType)),
        );
    }

    private addInterceptor(interceptorType: unknown): HttpService {
        return new HttpService(this.httpHandler, this.injector, [...this.interceptors, this.injector.get(interceptorType)]);
    }
}
