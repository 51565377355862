import { Component, inject, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent, IconComponent, PopupComponent } from '@app/shared/components';

@Component({
    selector: 'app-register-success',
    templateUrl: './register-success.component.html',
    styleUrls: ['./register-success.component.scss'],
    standalone: true,
    imports: [CommonModule, RouterModule, TranslateModule, ButtonComponent, IconComponent, PopupComponent],
})
export class RegisterSuccessComponent implements OnDestroy {
    private readonly activatedRoute = inject(ActivatedRoute);
    private readonly router = inject(Router);
    private readonly subscriptions$ = new Subscription();

    readonly email = this.activatedRoute.snapshot.params['email'];

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    onCloseClick() {
        this.router.navigate(['../../../..'], { relativeTo: this.activatedRoute });
    }
}
