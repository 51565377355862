import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { HereGeocodeModel } from '../models';

export const HeremapsClosestActions = createActionGroup({
    source: 'HeremapsClosest',
    events: {
        'Get Heremaps Closest Action': props<{ term: string; city: string; countryCode: string; lat: number; lng: number }>(),
        'Get Heremaps Closest Success Action': props<{
            term: string;
            city: string;
            countryCode: string;
            lat: number;
            lng: number;
            payload: HereGeocodeModel;
        }>(),
        'Get Heremaps Closest Error Action': props<{ term: string; city: string; countryCode: string; lat: number; lng: number }>(),
        'Clear Closest Action': emptyProps(),
    },
});
