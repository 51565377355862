import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { ShowSettingsService } from '../../../services';

export function hideSettings(): CanActivateFn {
    return () => {
        const showSettingsService = inject(ShowSettingsService);
        showSettingsService.setBookingDetailsFlag(false);
        return true;
    };
}
