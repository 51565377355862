import { Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { combineLatest, map } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@app/shared/components';
import { Time } from '@app/shared/classes';
import { PickerService } from '../../services/picker.service';
import { FocusService } from '../../services/focus.service';

@Component({
    selector: 'app-picker-days',
    templateUrl: './picker-days.component.html',
    styleUrls: ['./picker-days.component.scss'],
    standalone: true,
    imports: [CommonModule, TranslateModule, IconComponent],
})
export class PickerDaysComponent {
    private readonly pickerService = inject(PickerService);
    private readonly focusService = inject(FocusService);

    @Input() startDate!: string;
    @Input() endDate!: string;
    @Input() value!: string;

    readonly year$ = this.pickerService.year$;
    readonly month$ = this.pickerService.month$;

    readonly dates$ = combineLatest([this.year$, this.month$]).pipe(
        map(([year, month]) => {
            const firstDayOfMonthDate = new Date(year, month, 1);
            const lastDayOfMonthDate = new Date(year, month + 1, 0);
            const startDate = Time.removeDays(firstDayOfMonthDate, firstDayOfMonthDate.getDay() === 0 ? 6 : firstDayOfMonthDate.getDay() - 1);
            const endDate = Time.addDays(lastDayOfMonthDate, lastDayOfMonthDate.getDay() === 0 ? 1 : 8 - lastDayOfMonthDate.getDay());
            const values = [];

            while (startDate < endDate) {
                values.push({
                    date: new Date(startDate),
                    dateString: Time.dateToString(startDate, '/'),
                    value: Time.dateToString(startDate),
                    currentMonth: startDate.getMonth() === month,
                    isToday: Time.isToday(startDate),
                    blocked: this.isBlocked(startDate),
                });
                startDate.setDate(startDate.getDate() + 1);
            }

            return values;
        }),
    );

    onNextMonthClick(event: MouseEvent) {
        event.preventDefault();
        if (this.month$.value === 11) {
            this.year$.next(this.year$.getValue() + 1);
            this.month$.next(0);
        } else {
            this.month$.next(this.month$.getValue() + 1);
        }
    }

    onPrevMonthClick(event: MouseEvent) {
        event.preventDefault();
        if (this.month$.value === 0) {
            this.year$.next(this.year$.getValue() - 1);
            this.month$.next(11);
        } else {
            this.month$.next(this.month$.getValue() - 1);
        }
    }

    onMonthClick(event: MouseEvent) {
        event.preventDefault();
        this.pickerService.view$.next('months');
    }

    onSelectDate(event: MouseEvent, value: string) {
        event.preventDefault();
        this.focusService.selectValue$.next(value);
    }

    private isBlocked(date: Date): boolean {
        const startDateInvalid = this.startDate !== null && Time.stringIsDate(this.startDate) ? Time.stringToDate(this.startDate) > date : false;
        const endDateInvalid = this.endDate !== null && Time.stringIsDate(this.endDate) ? Time.stringToDate(this.endDate) < date : false;
        return startDateInvalid || endDateInvalid;
    }
}
