import { Injectable } from '@angular/core';
import { Time } from '@app/shared/classes';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class PickerService {
    readonly view$ = new BehaviorSubject<'days' | 'months'>('days');
    readonly year$ = new BehaviorSubject<number>(Time.getYear(Time.dateToString(Time.today())));
    readonly month$ = new BehaviorSubject<number>(<number>Time.getMonth(Time.dateToString(Time.today())));
}
