import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { HeremapsClosestActions } from '../actions/closest.actions';
import { HttpService } from '../services/http.service';
import { environment } from '@env/environment';

@Injectable()
export class HeremapsClosestEffects {
    getHeremapsClosest$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(HeremapsClosestActions.getHeremapsClosestAction),
            mergeMap((action) => {
                if (environment.integrations.hereMaps.v2 === true) {
                    return this.httpService.closestv2(action.term, action.city, action.countryCode, action.lat, action.lng).pipe(
                        map((item) =>
                            HeremapsClosestActions.getHeremapsClosestSuccessAction({
                                term: action.term,
                                city: action.city,
                                countryCode: action.countryCode,
                                lng: action.lng,
                                lat: action.lat,
                                payload: item,
                            }),
                        ),
                        catchError(() =>
                            of(
                                HeremapsClosestActions.getHeremapsClosestErrorAction({
                                    term: action.term,
                                    city: action.city,
                                    countryCode: action.countryCode,
                                    lng: action.lng,
                                    lat: action.lat,
                                }),
                            ),
                        ),
                    );
                } else {
                    return this.httpService.closest(action.term, action.city, action.countryCode, action.lat, action.lng).pipe(
                        map((item) =>
                            HeremapsClosestActions.getHeremapsClosestSuccessAction({
                                term: action.term,
                                city: action.city,
                                countryCode: action.countryCode,
                                lng: action.lng,
                                lat: action.lat,
                                payload: item,
                            }),
                        ),
                        catchError(() =>
                            of(
                                HeremapsClosestActions.getHeremapsClosestErrorAction({
                                    term: action.term,
                                    city: action.city,
                                    countryCode: action.countryCode,
                                    lng: action.lng,
                                    lat: action.lat,
                                }),
                            ),
                        ),
                    );
                }
            }),
        );
    });

    getHeremapsClosestSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(ofType(HeremapsClosestActions.getHeremapsClosestSuccessAction));
        },
        {
            dispatch: false,
        },
    );

    getHeremapsClosestError$ = createEffect(
        () => {
            return this.actions$.pipe(ofType(HeremapsClosestActions.getHeremapsClosestErrorAction));
        },
        {
            dispatch: false,
        },
    );

    constructor(
        private actions$: Actions,
        private httpService: HttpService,
    ) {}
}
