import { CommonModule } from '@angular/common';
import { Component, ElementRef, inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { delay, share, startWith, Subscription, tap } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@app/shared/components';
import { PickerService } from '../../services/picker.service';
import { PickerDaysComponent } from '../picker-days/picker-days.component';
import { PickerMonthsComponent } from '../picker-months/picker-months.component';
import { FocusService } from '../../services/focus.service';
import { ControlContainer, FormControl, FormGroup } from '@angular/forms';
import { Time } from '@app/shared/classes';

@Component({
    selector: 'app-form-calendar-picker',
    styleUrls: ['./picker.component.scss'],
    templateUrl: './picker.component.html',
    standalone: true,
    providers: [PickerService],
    imports: [CommonModule, TranslateModule, IconComponent, PickerDaysComponent, PickerMonthsComponent],
})
export class FormCalendarPickerComponent implements OnInit, OnDestroy {
    private readonly controlContainer = inject(ControlContainer);
    private readonly pickerService = inject(PickerService);
    private readonly focusService = inject(FocusService);

    @Input({ required: true }) controlName!: string;
    @Input() startDate!: string;
    @Input() endDate!: string;
    @Input() value!: string;
    @ViewChild('box') boxRef?: ElementRef<HTMLDivElement>;

    fc!: FormControl<string>;

    readonly view$ = this.pickerService.view$.pipe(
        tap(() => this.boxRef?.nativeElement.focus()),
        delay(0),
        share(),
        startWith(this.pickerService.view$.getValue()),
    );

    private subscriptions$ = new Subscription();

    ngOnInit() {
        this.fc = <FormControl<string>>(<FormGroup>this.controlContainer.control).get(this.controlName);

        if (Time.stringIsDate(this.fc.getRawValue())) {
            this.pickerService.year$.next(Time.getYear(this.fc.getRawValue()));
            this.pickerService.month$.next(<number>Time.getMonth(this.fc.getRawValue()));
        }
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
        this.fc.markAsTouched();
    }

    onClearClick(event: MouseEvent) {
        event.preventDefault();
        this.focusService.selectValue$.next('');
    }

    onCloseClick(event: MouseEvent) {
        event.preventDefault();
        this.focusService.close$.next(null);
    }
}
