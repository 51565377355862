import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { HeremapsAutosuggestActions } from '../actions/autosuggest.actions';
import { HttpService } from '../services/http.service';
import { environment } from '@env/environment';

@Injectable()
export class HeremapsAutosuggestEffects {
    private actions$ = inject(Actions);
    private httpService = inject(HttpService);

    getHeremapsLookup$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(HeremapsAutosuggestActions.getHeremapsAutosuggestAction),
            mergeMap((action) => {
                if (environment.integrations.hereMaps.v2 === true) {
                    return this.httpService.autosuggestv2(action.term, action.countries, action.skipCountryCode).pipe(
                        map((items) =>
                            HeremapsAutosuggestActions.getHeremapsAutosuggestSuccessAction({
                                term: action.term,
                                skipCountryCode: action.skipCountryCode,
                                payload: items,
                            }),
                        ),
                        catchError(() =>
                            of(
                                HeremapsAutosuggestActions.getHeremapsAutosuggestErrorAction({
                                    term: action.term,
                                    skipCountryCode: action.skipCountryCode,
                                }),
                            ),
                        ),
                    );
                } else {
                    return this.httpService.autosuggest(action.term, action.countries, action.skipCountryCode).pipe(
                        map((items) =>
                            HeremapsAutosuggestActions.getHeremapsAutosuggestSuccessAction({
                                term: action.term,
                                skipCountryCode: action.skipCountryCode,
                                payload: items,
                            }),
                        ),
                        catchError(() =>
                            of(
                                HeremapsAutosuggestActions.getHeremapsAutosuggestErrorAction({
                                    term: action.term,
                                    skipCountryCode: action.skipCountryCode,
                                }),
                            ),
                        ),
                    );
                }
            }),
        );
    });
}
