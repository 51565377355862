{
  "account.activate.message.error": "Посилання для активації недійсне або його термін дії закінчився.",
  "account.activate.message.success": "Ваш обліковий запис активовано.",
  "account.login.form.forot-password": "Ви не пам'ятаєте пароль?",
  "account.login.form.login": "Електронна пошта",
  "account.login.form.password": "Пароль",
  "account.login.login.title": "Увійти",
  "account.login.multidevice.info-1": "Пам’ятайте!",
  "account.login.multidevice.info-2": "Від магазину GTV та мобільного додатку GTV Bus, ви маєте однакові електронну пошту та пароль.",
  "account.login.rigister.description.1": "Зареєструйтеся через форму та залишайтеся на зв’язку з нами!",
  "account.login.rigister.description.2": "Пам'ятайте, що завдяки реєстрації:",
  "account.login.rigister.list.1": "Ви отримаєте безкоштовний обліковий запис пасажира",
  "account.login.rigister.list.2": "Ви отримаєте доступ до останніх пропозицій та акцій",
  "account.login.rigister.list.3": "Ви отримаєте повний огляд історії ваших замовлень",
  "account.login.rigister.title": "У вас ще немає облікового запису? Нічого не втрачено!",
  "account.popup.back-to-login": "Назад до входу",
  "account.popup.forgot-password": "Скинути пароль",
  "account.popup.forgot-password.form.email": "Електронна пошта",
  "account.popup.forgot-password.title": "Скинути пароль",
  "account.popup.login.form.login": "Логін",
  "account.popup.login.form.password": "Пароль",
  "account.popup.login.title": "Увійти",
  "account.popup.register": "Створити аккаунт",
  "account.popup.register.form.login": "Електронна пошта",
  "account.popup.register.form.password": "Пароль",
  "account.popup.register.title": "Реєстрація",
  "account.register.description": "Просто заповніть поля вище. Решту інформації ви можете надати пізніше.",
  "account.register.form.regulation": "Положення",
  "account.register.form.select-all": "Вибрати всі",
  "account.register.success.description": "Щоб завершити реєстрацію облікового запису, підтвердьте її, натиснувши посилання, надане за такою адресою:",
  "account.register.success.title": "Дякуємо за створення облікового запису",
  "account.resend-activation-link.message.success": "Посилання для активації надіслано знову",
  "alert.error.booking-order-excceeded": "На жаль, вибрана поїздка недоступна. Зателефонуй на нашу інфолінію: +48 77 544 57 88",
  "alert.error.booking-order-limit-excceeded": "Ви досягли ліміту пасажирів для вибраного маршруту.",
  "alert.error.generic": "Сталася неочікувана помилка",
  "alert.error.generic-with-contact": "Сталася неочікувана помилка. Зателефонуй на нашу інфолінію: +48 77 544 57 88",
  "alert.error.generic.not-found": "Не вдалося знайти ресурс",
  "alert.error.paymnet-overdue": "Термін оплати замовлення закінчився. Зверніться до служби підтримки клієнтів.",
  "alerts.mydata.change.success": "Дані були змінені правильно",
  "alerts.password.change.success": "Пароль було змінено правильно",
  "auth.token.expired": "Ваш сеанс закінчився",
  "booking.confirmation.account.cutomer.type.company": "Фірма",
  "booking.confirmation.account.cutomer.type.person": "Приватна особа",
  "booking.confirmation.account.options.login": "У мене вже є обліковий запис і я хочу увійти",
  "booking.confirmation.account.options.register": "Я хочу створити обліковий запис під час бронювання",
  "booking.confirmation.account.options.without-account": "Бронювання без створення облікового запису",
  "booking.confirmation.billing.note.long": "Звертаємо Вашу увагу, що відповідно до чинних нормативно-правових актів НЕ можна виставити рахунок-фактуру з ПДВ для компанії, якщо реквізити рахунка-фактури (включаючи ідентифікаційний податковий номер компанії) не вказано на етапі оформлення замовлення.",
  "booking.confirmation.billing.title": "Дані для рахунку",
  "booking.confirmation.customer.details": "Деталі бронювання:",
  "booking.confirmation.customer.invoice": "Дані для рахунку:",
  "booking.confirmation.discount.form.code.applied": " Купон застосовано",
  "booking.confirmation.discount.form.code.placeholder": "Промокод",
  "booking.confirmation.fail.description": "Поновіть оплату в розділі Мій обліковий запис / Мої бронювання або зв'яжіться з нашим номером телефону BOK: +48 77 544 57 88, електронна пошта: info@gtvbus.pl",
  "booking.confirmation.fail.title": "Під час оплати сталася помилка",
  "booking.confirmation.optional.comments.placeholder": "Будь ласка, введіть своє повідомлення",
  "booking.confirmation.optional.comments.switch.description": "Поставте прапорець, якщо ви хочете надати нам додаткову інформацію про своє замовлення",
  "booking.confirmation.optional.comments.switch.title": "Коментарі щодо бронювання (необов'язково)",
  "booking.confirmation.optional.title": "За бажанням",
  "booking.confirmation.party.form.city": "Місто",
  "booking.confirmation.party.form.companyName": "Назва компанії",
  "booking.confirmation.party.form.country": "Країна",
  "booking.confirmation.party.form.email": "Електронна пошта",
  "booking.confirmation.party.form.email.helptext": "Ми надішлемо квиток на вказану адресу",
  "booking.confirmation.party.form.firstname": "Ім'я",
  "booking.confirmation.party.form.firstname-and-lastname": "Ім'я та прізвище",
  "booking.confirmation.party.form.lastname": "Прізвище",
  "booking.confirmation.party.form.nip": "NIP (податковий ідентифікаційний номер)",
  "booking.confirmation.party.form.phone": "Телефон",
  "booking.confirmation.party.form.phone.helptext": "SMS тільки в разі затримки або зміни маршруту",
  "booking.confirmation.party.form.postalCode": "Поштовий індекс",
  "booking.confirmation.party.form.street": "Вулиця і номер",
  "booking.confirmation.party.mirror-passenger": "Я хочу надати інші дані для пасажира, ніж для бронювання",
  "booking.confirmation.party.title": "Дані резервування",
  "booking.confirmation.party.title.passenger": "{{ i }}. Пасажир",
  "booking.confirmation.payment.provider.description.payu-ap": "Оплата карткою, збереженою у вашому обліковому записі Google Pay",
  "booking.confirmation.payment.provider.description.payu-blik": "Оплата кодом БЛІК",
  "booking.confirmation.payment.provider.description.payu-c": "Оплата після надання реквізитів платіжної картки",
  "booking.confirmation.payment.provider.description.payu-dpp": "Відстрочка платежу до 30 днів",
  "booking.confirmation.payment.provider.description.payu-dpt": "Відстрочка платежу до 30 днів",
  "booking.confirmation.payment.provider.description.payu-jp": "Оплата карткою, яка збережена у вашому гаманці Apple Pay",
  "booking.confirmation.payment.provider.description.payu-pw": "Оплата онлайн переказом з рахунку в польському банку",
  "booking.confirmation.payment.provider.description.stripe-bancontact": "Оплата онлайн-переказом з рахунку в бельгійському банку",
  "booking.confirmation.payment.provider.description.stripe-ideal": "Оплата онлайн-переказом з голландського банківського рахунку",
  "booking.confirmation.payment.provider.description.transfer-transfer": "Оплата на номер банківського рахунку",
  "booking.confirmation.payment.provider.payu-ap": "Google Pay",
  "booking.confirmation.payment.provider.payu-blik": "Blik",
  "booking.confirmation.payment.provider.payu-c": "Оплата карткою",
  "booking.confirmation.payment.provider.payu-dpp": "Оплатіть пізніше",
  "booking.confirmation.payment.provider.payu-dpt": "Twisto Оплатіть пізніше",
  "booking.confirmation.payment.provider.payu-jp": "Apple Pay",
  "booking.confirmation.payment.provider.payu-pw": "Швидка транзакція",
  "booking.confirmation.payment.provider.stripe-bancontact": "Bancontact",
  "booking.confirmation.payment.provider.stripe-ideal": "Ideal",
  "booking.confirmation.payment.provider.transfer-transfer": "традиційний переказ",
  "booking.confirmation.register.form.email": "Повторіть свою електронну адресу",
  "booking.confirmation.register.form.password": "Пароль",
  "booking.confirmation.register.form.password.helptext": "Пароль облікового запису",
  "booking.confirmation.register.title": "Реєстрація облікового запису",
  "booking.confirmation.status.confirmed": "Бронювання очікує на оплату",
  "booking.confirmation.status.paid": "Бронювання вже оплачено",
  "booking.confirmation.success.bank-transfer.description.1": "Разом з тим повідомляємо, що умовою виконання переїзду буде його оплата.",
  "booking.confirmation.success.bank-transfer.description.2": "У разі несплати поїздки бронювання буде скасовано.",
  "booking.confirmation.success.bank-transfer.details.1": "Просимо здійсніти оплату:",
  "booking.confirmation.success.bank-transfer.details.2": "GTV Office Sp. z o.o. ul. Hutnicza 5 46-040 Ozimek",
  "booking.confirmation.success.bank-transfer.details.3.EUR": "Номер рахунку для платежів EUR: ",
  "booking.confirmation.success.bank-transfer.details.3.PLN": "Номер рахунку для платежів PLN: ",
  "booking.confirmation.success.bank-transfer.details.4": "ING Bank Śląski",
  "booking.confirmation.success.bank-transfer.details.5": "SWIFT КОД: INGBPLPW",
  "booking.confirmation.success.bank-transfer.details.6": "Детальну інформацію про суму та назву переказу можна знайти в отриманому електронному листі.",
  "booking.confirmation.success.bank-transfer.details.7": "Умовою здійснення платежу являється підтвердження вислане на пошту: <a href=\"mailto:przelew@gtvbus.pl\">przelew@gtvbus.pl</a>",
  "booking.confirmation.success.bank-transfer.number.EUR": "23 1050 1504 1000 0090 8133 6084",
  "booking.confirmation.success.bank-transfer.number.PLN": "45 1050 1504 1000 0090 8133 6076",
  "booking.confirmation.success.description": "Ми надішлемо підтвердження бронювання разом з квитком на адресу, вказану під час бронювання",
  "booking.confirmation.success.title": "Ваше бронювання успішно завершено",
  "booking.confirmation.summary.reservation": "Ваше бронювання",
  "booking.confirmation.summary.total-include-tax": "Усього (включаючи ПДВ)",
  "booking.confirmation.summary.x.adults": "{{ x }} x пасажир",
  "booking.confirmation.summary.x.options-name": "{{ x }} x {{ name }}",
  "booking.confirmation.summary.x.options-name-discount": "Промокод <span class='text-secondary'>{{ name }}</span>",
  "booking.confirmation.title.additional-options": "Додаткові опції",
  "booking.confirmation.title.payment": "Оплата",
  "booking.confirmation.title.reservation-details": "Дані для бронювання",
  "booking.find.no-address-found": "Адреса не знайдена",
  "booking.nav.parcel": "Надішліть посилку",
  "booking.nav.passengers": "Зарезервуйте поїздку",
  "booking.option.type.1.negative.name": "Поїздка",
  "booking.option.type.1.positive.name": "Поїздка",
  "booking.option.type.2.negative.name": "Додатковий багаж",
  "booking.option.type.2.positive.name": "Додатковий багаж",
  "booking.option.type.3.negative.name": "Рабат",
  "booking.option.type.3.positive.name": "Доплата",
  "booking.option.type.4.negative.name": "Посилка",
  "booking.option.type.4.positive.name": "Посилка",
  "booking.option.type.6.negative.name": "Гарантія повернення вартості квитка",
  "booking.option.type.6.positive.name": "Гарантія повернення вартості квитка",
  "booking.option.type.7.negative.name": "Рабат",
  "booking.option.type.7.positive.name": "Доплата",
  "booking.options.2.0.alternative.description": "Багаж (входить у вартість квитка)",
  "booking.options.2.0.description": "Не вибрано додаткові предмети багажу до 20 кг ",
  "booking.options.2.1.alternative.description": "1 ручна поклажа 5 кг + максимум 2 одиниці багажу загальною вагою до 40 кг",
  "booking.options.2.1.description": "{{ count }} додаткове місце багажу до 20 кг",
  "booking.options.2.2-4.description": "{{ count }} додаткові місця багажу до  20 кг",
  "booking.options.2.5+.description": "{{ count }} додаткових місць багажу до 20 кг",
  "booking.options.2.title": "Додатковий багаж для пасажирів",
  "booking.options.6.description": "Детальну інформацію про гарантію можна знайти в Статуті",
  "booking.options.6.title": "Гарантія повернення вартості квитка",
  "booking.options.passenger-no": "Пасажир {{ index }}",
  "booking.passenger.calendar.no-connections": "Немає результатів",
  "booking.passenger.calendar.price.from": "від {{ price }}",
  "booking.passenger.calendar.price.price-from": "Ціна від {{ price }}",
  "booking.passenger.return": "Повернення",
  "booking.passenger.search.add": "Додати",
  "booking.passenger.search.adults": "Особа",
  "booking.passenger.search.amount": "Кількість людей",
  "booking.passenger.search.amount.informations.link": "Побачити більше",
  "booking.passenger.search.amount.informations.text": "Інформація про знижки та тарифи",
  "booking.passenger.search.amount.input.1": "{{ amount }} особа",
  "booking.passenger.search.amount.input.2-4": "{{ amount }} особи",
  "booking.passenger.search.amount.input.5-9": "{{ amount }} осіб",
  "booking.passenger.search.animals": "Тварина",
  "booking.passenger.search.children": "Дитина",
  "booking.passenger.search.depart": "Відправлення",
  "booking.passenger.search.from": "Звідки",
  "booking.passenger.search.return": "Повернення",
  "booking.passenger.search.to": "Куди",
  "booking.passenger.transit": "Поїздка",
  "booking.passenger.transit.arrival": "Прибуття",
  "booking.passenger.transit.depart-after-midnight": "Відправка відбувається після півночі наступного дня.",
  "booking.passenger.transit.depart-before-midnight": "Виїзд відбувається до півночі попереднього дня.",
  "booking.passenger.transit.description": "Будь ласка, зв’яжіться з нашою службою підтримки клієнтів.",
  "booking.passenger.transit.from": "З {{ address }}",
  "booking.passenger.transit.mail": "емейл:",
  "booking.passenger.transit.not-found": "Цей маршрут обслуговується після консультації з нашою інфолінією.",
  "booking.passenger.transit.or": "або",
  "booking.passenger.transit.phone": "Телефон:",
  "booking.passenger.transit.phone.number.href": "+48775445788",
  "booking.passenger.transit.phone.number.text": "+48 77 544 57 88",
  "booking.passenger.transit.price.total": "Разом",
  "booking.passenger.transit.selected-depart-transit": "Вибране бронювання",
  "booking.passenger.transit.to": "До {{ address }}",
  "booking.search.alert.no-transits-found": "На жаль, {{ date }} вибрана поїздка недоступна. Ось найближча дата:",
  "booking.status.expired": "Термін дії вашого бронювання закінчився, розпочніть резервацію спочатку",
  "booking.status.no-access": "Недійсне або неактивне замовлення",
  "button.activate": "Активуйте",
  "button.add": "Додайте",
  "button.add-discount-code": " Додайте промокод",
  "button.apply": "Застосуй",
  "button.back": "Повернення",
  "button.back-to-main-page": "Поверніться на домашню сторінку",
  "button.book": "Забронюй",
  "button.booking-avaialble-count": "Доступні місця: {{ count }}",
  "button.buy-and-pay": "Перейти до оплати",
  "button.collapse": "Згорнути",
  "button.continue": "Продовжити",
  "button.create-account": "Створити аккаунт",
  "button.edit": "Редагувати",
  "button.edit-booking": "Відредагуй своє бронювання",
  "button.expand": "Розгорнути",
  "button.limit-reached": "Немає вільних місць",
  "button.login": "Увійти",
  "button.logout-anyway": "Вийти",
  "button.no": "Ні",
  "button.password-reset": "Встановіть пароль",
  "button.register": "Зареєструйся",
  "button.remove-discount-code": " Видаліть промокод",
  "button.request-password-reset": "Надіслати посилання",
  "button.reset-password": "Скиньте пароль",
  "button.save": "Зберегти",
  "button.search-connections": "Пошук маршрутів",
  "button.select": "Вибрати",
  "button.yes": "Так",
  "calendar.button.delete": "Видалити",
  "calendar.button.passenger.transit.details": "Деталі маршруту",
  "calendar.months": "Місяці",
  "calendar.weekday.short.0": "нд.",
  "calendar.weekday.short.1": "пн.",
  "calendar.weekday.short.2": "вт.",
  "calendar.weekday.short.3": "ср.",
  "calendar.weekday.short.4": "чт.",
  "calendar.weekday.short.5": "пт.",
  "calendar.weekday.short.6": "сб.",
  "calendar.years": "років",
  "country.AUT": "Австрія",
  "country.BEL": "Бельгія",
  "country.DEU": "Німеччина",
  "country.NLD": "Нідерланди",
  "country.POL": "Польща",
  "country.ROU": "Румунія",
  "country.UKR": "Україна",
  "currency.dropdown.select-currency": "Виберіть валюту",
  "currency.dropdown.select-language": "Виберіть мову",
  "currency.select-currency": "Виберіть валюту",
  "currency.select-currency-and-language": "Виберіть мову та валюту",
  "footer.copyright": "© GTV Bus. All Rights Reserved",
  "footer.nav.for-companies": "Для компаній",
  "footer.nav.how-to-buy": "Відео → Як купити поїздку",
  "footer.nav.privacy-policy": "Політика конфіденційності",
  "footer.nav.recruitment": "Вакансії",
  "footer.nav.regulations": "Статут",
  "footer.powered-by": "Працює на",
  "footer.social-media": "Соціальні мережі",
  "forms.error.message.email": "Невірна адреса електронної пошти",
  "forms.error.message.general": "Недійсне значення поля",
  "forms.errors.message.nip": "Недійсне значення поля",
  "forms.error.message.invalid_address": "Введіть назву міста, вулицю та номер будинку",
  "forms.error.message.invalid_lookup_id": "Не вдалося отримати адресу",
  "forms.error.message.invalid_return_date": "Недійсна дата повернення",
  "forms.error.message.invalid_street": "Введіть назву вулиці та номер будинку",
  "forms.error.message.invalid_street_number": "Введіть номер будинку",
  "forms.error.message.max_length": "Максимальна кількість символів – {{ max_length }}",
  "forms.error.message.new_password": "Новий пароль має бути однаковим в обох полях",
  "forms.error.message.postal-code": "Неправильний поштовий індекс",
  "forms.error.message.register_email_not_match": "Адреса електронної пошти не збігається з адресою особи, яка здійснює бронювання",
  "forms.error.message.required": "Це поле є обов'язковим",
  "forms.error.message.words": "У вмісті поля є недозволені символи",
  "header.nav.help": "Допомога",
  "header.nav.safety": "Безпека",
  "heremaps.form.address-not-found": "Я не можу знайти адресу",
  "heremaps.form.address.from": "Введіть адресу",
  "heremaps.form.address.to": "Введіть адресу",
  "heremaps.form.city": "Місто",
  "heremaps.form.nothing-found": "Адреса не знайдена",
  "heremaps.form.number": "Будинок",
  "heremaps.form.street": "Вул",
  "label.busstops": "Зупинки",
  "language.pl-PL": "PL",
  "language.ua-UA": "UA",
  "location.detection": "Визначення місця розташування",
  "location.detection.access.denied": "Доступ до локації заборонено",
  "location.detection.found.title": "Місцезнаходження виявлено",
  "location.detection.found.want-to-use-it": "Ви хочете цим скористатися? ",
  "location.detection.not-found.title": "Місцезнаходження не виявлено",
  "login.message.error.invalid-credentials": "Електронна адреса або пароль неправильні",
  "login.message.error.not-activated": "Обліковий запис не активовано.",
  "login.message.error.not-activated-link": "Надішліть повторно електронний лист із посиланням для активації",
  "login.message.success.log-in": "Вхід правильний",
  "logout.message.error": "Під час виходу сталася помилка",
  "logout.message.success.logged-out": "Вихід правильний",
  "order.exception.not_found": "Замовлення не знайдено",
  "register.title": "Реєстрація",
  "regulations.checkbox.accept": "Я ознайомився і прийняв",
  "regulations.checkbox.accept.and": "і",
  "regulations.checkbox.accept.privacy-policy": "Політику Конфіденційності",
  "regulations.checkbox.accept.regulation": "Положення про сайт",
  "regulations.checkbox.accept.service": "Сервісу*",
  "regulations.checkbox.marketing.1.long": "Я даю згоду на обробку моїх персональних даних для маркетингових цілей відповідно до ст. 6 розд. 1 літ. Загальне положення про захист персональних даних від 27 квітня 2016 р. (Вісник законів UE L 119 від 05.04.2016 р.) та Закон про захист персональних даних від 10 травня 2018 р. (Вісник законів 2018 р., ст. 1000) GTV Office Sp. z o.o.",
  "regulations.checkbox.marketing.1.short": "Я даю згоду на обробку моїх персональних даних для маркетингових цілей",
  "regulations.checkbox.marketing.2.long": "Я даю згоду на телефонний маркетинг із використанням телекомунікаційного термінального обладнання (включаючи телефон) та систем автоматичного виклику, зокрема, наданого мною номера телефону, для цілей прямого маркетингу GTV Office Sp. z o.o. відповідно до ст. 172 сек. 1 Закону від 16 липня 2004 року Закон про телекомунікації.",
  "regulations.checkbox.marketing.2.short": "Я даю згоду на телефонний маркетинг",
  "reset-password.message.error": "Посилання недійсне або його термін дії закінчився.",
  "reset-password.message.success": "Новий пароль збережено.",
  "title": "GTV PL",
  "user.agreements.content": "Поставте прапорці та насолоджуйтесь усіма перевагами облікового запису GTV. Пам’ятайте, ми не розсилаємо спам і дбаємо про ваш комфорт не тільки в дорозі, а й перед екраном телефону чи комп’ютера :) Зазначення сповіщень також означає, що ви будете впевнені, що вся необхідна інформація буде доставлена ​​вам вчасно.",
  "user.agreements.title": "Згода на сповіщення",
  "user.alerts.add": "Дані додано правильно",
  "user.alerts.delete": "Дані успішно видалено",
  "user.alerts.update": "Дані були змінені правильно",
  "user.delete.content.1": "Якщо ви хочете видалити свій обліковий запис, зв’яжіться з нами електронною поштою за адресою",
  "user.delete.content.2": "УВАГА! Видалення облікового запису призведе до безповоротної втрати всіх даних, включаючи: збережені адреси, історію замовлень, а також унеможливить вхід до програми пасажира. Видалений обліковий запис не можна повторно активувати, дані видаляються безповоротно.",
  "user.delete.title": "Видалити аккаунт",
  "user.logout.confirmation.button.no": "ні",
  "user.logout.confirmation.button.yes": "Так",
  "user.logout.confirmation.title": "Ви впевнені, що бажаєте вийти?",
  "user.logout.content": "Натиснувши кнопку нижче, ви вийдете з веб-сайту. Ви не зможете використовувати його функції, зокрема збережені адреси, історію та розміщення замовлень.",
  "user.logout.title": "Вихід з сайту",
  "user.mydata.account": "Рахунок",
  "user.mydata.active_from": "Активне від {{ date }}",
  "user.mydata.birthdate": "Дата народження",
  "user.mydata.birthdate.text": "Вкажіть свою дату народження, і завдяки цьому ми зможемо надіслати Вам привітальний бонус.",
  "user.mydata.button.edit": "РЕДАГУЙ",
  "user.mydata.button.submit": "Змінити",
  "user.mydata.firstname": "Ім'я",
  "user.mydata.fullname": "Ім'я та прізвище",
  "user.mydata.lastname": "Прізвище",
  "user.mydata.login": "Пошта (логін)",
  "user.mydata.name_surname": "Ім'я та прізвище",
  "user.mydata.phone": "Телефон",
  "user.mydata.set_birthdate": "Встановіть свою дату народження",
  "user.mydata.set_name": "Встановіть своє ім’я та прізвище",
  "user.mydata.set_phone": "Встановіть номер телефону",
  "user.mydata.title": "  Мої дані",
  "user.password.new": "Новий пароль",
  "user.password.new_repeat": "Повторіть новий пароль",
  "user.password.old": "Старий пароль",
  "user.password.submit": "Змінити пароль",
  "user.password.title": "Зміна пароля",
  "user.reservation.buttons.delete": "Видалити",
  "user.reservation.buttons.edit": "Редагувати",
  "user.reservation.buttons.new": "Інформація про особу, яка здійснює бронювання",
  "user.reservation.buttons.no": "Ні",
  "user.reservation.buttons.save": "Зберегти",
  "user.reservation.buttons.yes": "Так",
  "user.reservation.delete.title": "Хочеш видалити вибрані дані бронювання?",
  "user.reservation.form.city": "Місто",
  "user.reservation.form.company_name": "Назва фірми",
  "user.reservation.form.country": "Країна",
  "user.reservation.form.email": "Електронна пошта",
  "user.reservation.form.firstname": "Ім'я",
  "user.reservation.form.is_company": "Фірма",
  "user.reservation.form.is_private": "Приватна особа",
  "user.reservation.form.lastname": "Прізвище",
  "user.reservation.form.nip": "NIP (податковий ідентифікаційний номер)",
  "user.reservation.form.phone": "Телефон",
  "user.reservation.form.postal_code": "Поштовий індекс",
  "user.reservation.form.street": "Вулиця і номер",
  "user.reservation.form.title_invoice": "Дані для рахунків",
  "user.reservation.invoice": "Дані для рахунків",
  "user.reservation.title": "Дані для бронювання",
  "user.reservations.buttons.pay": "Заплатити",
  "user.reservations.buttons.search": "Шукай з'єднання",
  "user.reservations.buttons.ticket": "Завантажити рахунок/квиток",
  "user.reservations.headers.cost": "Вартість",
  "user.reservations.headers.number": "Номер замовлення",
  "user.reservations.headers.order_date": "Дата покупки",
  "user.reservations.headers.passengers": "Пасажири",
  "user.reservations.headers.payment_status": "Стан платежу",
  "user.reservations.headers.price": "Ціна",
  "user.reservations.headers.return": "Повернення",
  "user.reservations.headers.route": "Поїздка",
  "user.reservations.headers.services": "Послуги",
  "user.reservations.headers.ticket": "Квиток і рахунок",
  "user.reservations.no-transits-found": "Подорожів не знайдено",
  "user.reservations.none": "Брак",
  "user.reservations.sevices.insurance": "Страхування",
  "user.reservations.sevices.luggage": "Додатковий багаж",
  "user.reservations.sevices.route": "Поїздка",
  "user.reservations.status.canceled": "Скасовано",
  "user.reservations.status.payed": "Оплачено",
  "user.reservations.status.unpayed": "Неоплачено",
  "user.reservations.tabs.incoming": "Майбутні подорожі",
  "user.reservations.tabs.realized": "Завершені подорожі",
  "user.tabs.reservations": "Мої застереження",
  "user.tabs.settings": "Налаштування аккаунта",
  "user.title": "Мій рахунок",
  "booking.confirmation.party.underaged": "Для неповної особи",
  "booking.passenger.statement.underaged.title": "Пам’ятай!",
  "booking.passenger.statement.underaged.1": "Для дитини зростом до 150 см необхідно підготувати з собою автокрісло/подкладку.",
  "booking.passenger.statement.underaged.2": "Дитина до 12 років може подорожувати тільки в супроводі опікуна.",
  "booking.passenger.statement.underaged.3a": "Дитина старше 12 років може подорожувати без супроводу лише після заповнення Вами заяви.",
  "booking.passenger.statement.underaged.3b": "Завантажити заяву",
  "booking.passenger.statement.underaged.3c": ".",
  "booking.options.unavailable.title": "Увага!",
  "booking.options.unavailable.contact": "Щоб придбати цю додаткову опцію, зверніться на гарячу лінію за номером ",
  "booking.options.unavailable.contact.href": "+48775445788",
  "booking.options.unavailable.contact.text": "+48 77 544 57 88",
  "button.reload-page": "Будь ласка, оновіть поточну сторінку",
  "alert.error.generic.comma": "Сталася неочікувана помилка, ",
  "booking.passenger.search.place": "Місто",
  "booking.search.alert.incomplete": "Щоб зробити бронювання переїзду вкажіть назву вулиці та номер будинку",
  "booking.passenger.search.address.incomplete": "Щоб зробити бронювання переїзду вкажіть назву вулиці та номер будинку",
  "booking.passenger.transit.depart": "Відправлення з пункту:",
  "booking.passenger.transit.arrival.isPoint": "Прибуття на зупинку:",
  "booking.passenger.transit.arrival.to": "Прибуття до",
  "booking.passenger.transit.depart.from": "Виїзд з",
  "booking.passenger.transit.depart.isPoint": "Виїзд із зупинки:",
  "calendar.button.passenger.transit.edit": "Редагувати",
  "calendar.button.passenger.transit.edit-hide": "Приховати",
  "calendar.from.bus-stop": "Від зупинки",
  "calendar.from.point": "Від.пункту",
  "calendar.mixed_ride": "Pанок подорож",
  "calendar.button.passenger.transit.edit-show": "Редагувати",
  "calendar.button.passenger.transit.map-show": "Показати карту",
  "calendar.button.passenger.transit.map-hide": "Приховати карту",
  "          v2          ": "          v2          ",
  "        - v2 -        ": "        - v2 -        ",
  "       -- v2 --       ": "       -- v2 --       ",
  "      --- v2 ---      ": "      --- v2 ---      ",
  "     ---- v2 ----     ": "     ---- v2 ----     ",
  "    ----- v2 -----    ": "    ----- v2 -----    ",
  "   ------ v2 ------   ": "   ------ v2 ------   ",
  "  ------- v2 -------  ": "  ------- v2 -------  ",
  " -------- v2 -------- ": " -------- v2 -------- ",
  "--------  v2 ---------": "--------  v2 ---------",
  "nav.how-to-book": "Як забронювати?",
  "nav.luggage": "Багаж",
  "nav.security": "Безпека",
  "nav.local-distribution": "Місцевий розклад руху",
  "nav.contact": "Звʼязок",
  "header.auth.login": "Увійти",
  "header.shop.auth.login": "Увійти",
  "header.shop.auth.register": "Зареєструватися",
  "home.banner.text.1": "Привозимо!",
  "home.banner.text.2": "– знаємо, яким чином!",
  "home.contact.text.1": "Є запитання?",
  "home.contact.text.2a": "Підтримка клієнтів працює 7 днів на тиждень",
  "home.contact.text.2b": "з 08:00 до 21:00",
  "home.contact.text.call-us": "Зателефонуйте нам",
  "home.contact.text.phone-number": "+48 606 200 002",
  "nav.footer.for-companies": "Компаніям",
  "nav.footer.video": "Ролик  як придбати проїзд",
  "nav.footer.recruitment": "Набір",
  "nav.footer.affiliate-program": "Партнерська програма",
  "nav.footer.gtv-bus-group": "GTV BUS GROUP",
  "search.widget.title": "Пошук поїздки",
  "search.widget.directions.one-way": "В один бік",
  "search.widget.directions.two-way": "Туди й назад",
  "search.widget.form.button.search": "Пошук",
  "search.widget.form.button.search-connections": "Пошук сполучень",
  "search.widget.form.address.from.label": "Звідки їдете?",
  "search.widget.form.address.from.placeholder": "Вкажіть адресу",
  "search.widget.form.address.from.label-city": "Вкажіть місто",
  "search.widget.form.address.from.placeholder-city": "З якого міста їдете?",
  "search.widget.form.address.from.label-street": "Вкажіть вулицю",
  "search.widget.form.address.from.placeholder-street": "Вулиця",
  "search.widget.form.address.from.label-housenumber": "Вкажіть номер будинку",
  "search.widget.form.address.from.placeholder-housenumber": "Номер будинку",
  "search.widget.form.address.to.label": "Куди їдете?",
  "search.widget.form.address.to.placeholder": "Вкажіть адресу",
  "search.widget.form.address.to.label-city": "Вкажіть місто",
  "search.widget.form.address.to.placeholder-city": "До якого міста подорожуєте?",
  "search.widget.form.address.to.label-street": "Вкажіть вулицю",
  "search.widget.form.address.to.placeholder-street": "Вулиця",
  "search.widget.form.address.to.label-housenumber": "Вкажіть номер будинку",
  "search.widget.form.address.to.placeholder-housenumber": "Номер будинку",
  "search.widget.form.address.button.my-location": "Застосувати моє розташування",
  "search.widget.form.address.button.cannot-find": "Не вдається знайти адресу",
  "search.widget.form.address-not-found": "Адресу не знайдено",
  "search.widget.form.depart.label": "Відправлення",
  "search.widget.form.depart.placeholder": "Вибрати дату",
  "search.widget.form.return.label": "Відправлення назад",
  "search.widget.form.return.placeholder": "Вибрати дату",
  "search.widget.form.address.incomplete": "Щоб забронювати, вкажіть назву вулиці та номер будинку",
  "btn.delete": "Прибрати",
  "search.widget.form.passengers.label": "Скільки осіб",
  "search.widget.form.passengers-count.label": "Скільки осіб?",
  "search.widget.form.passengers-count.option.person": "Особа",
  "search.widget.form.passengers-count.value.passengers.0": "{{ count }} осіб",
  "search.widget.form.passengers-count.value.passengers.1": "{{ count }} особа",
  "search.widget.form.passengers-count.value.passengers.2-4": "{{ count }} особи",
  "search.widget.form.passengers-count.value.passengers.5+": "{{ count }} осіб",
  "calendar.month.0": "Січень",
  "calendar.month.1": "Лютий",
  "calendar.month.2": "Березень",
  "calendar.month.3": "Квітень",
  "calendar.month.4": "Травень",
  "calendar.month.5": "Червень",
  "calendar.month.6": "Липень",
  "calendar.month.7": "Серпень",
  "calendar.month.8": "Вересень",
  "calendar.month.9": "Жовтень",
  "calendar.month.10": "Листопад",
  "calendar.month.11": "Грудень",
  "calendar.month.short.0": "січ",
  "calendar.month.short.1": "лют",
  "calendar.month.short.10": "лис",
  "calendar.month.short.11": "гру",
  "calendar.month.short.2": "бер",
  "calendar.month.short.3": "кві",
  "calendar.month.short.4": "тра",
  "calendar.month.short.5": "чер",
  "calendar.month.short.6": "лип",
  "calendar.month.short.7": "сер",
  "calendar.month.short.8": "вер",
  "calendar.month.short.9": "жов",
  "calendar.month.short.-": "?",
  "calendar.weekday.letters.0": "нед",
  "calendar.weekday.letters.1": "пон",
  "calendar.weekday.letters.2": "вів",
  "calendar.weekday.letters.3": "сер",
  "calendar.weekday.letters.4": "чет",
  "calendar.weekday.letters.5": "пʼят",
  "calendar.weekday.letters.6": "суб",
  "calendar.weekday.letter.0": "нед",
  "calendar.weekday.letter.1": "П",
  "calendar.weekday.letter.2": "В",
  "calendar.weekday.letter.3": "С",
  "calendar.weekday.letter.4": "Ч",
  "calendar.weekday.letter.5": "П",
  "calendar.weekday.letter.6": "С",
  "calendar.weekday.0": "Неділя",
  "calendar.weekday.1": "Понеділок",
  "calendar.weekday.2": "Вівторок",
  "calendar.weekday.3": "Середа",
  "calendar.weekday.4": "Четвер",
  "calendar.weekday.5": "Пʼятниця",
  "calendar.weekday.6": "Субота",
  "btn.apply": "Застосувати",
  "forms.errors.message.email": "Неправильна адреса електронної пошти",
  "forms.errors.message.general": "Неправильне значення в графі",
  "forms.errors.message.invalid-address": "Вкажіть назву населеного пункту, вулицю і номер будинку",
  "forms.errors.message.invalid-lookup_id": "Не вдається завантажити адресу",
  "forms.errors.message.invalid-return-date": "Неправильна дата зворотної поїздки",
  "forms.errors.message.invalid-street": "Вкажіть назву вулиці і номер будинку",
  "forms.errors.message.invalid-street_number": "Вкажіть номер будинку",
  "forms.errors.message.max_length": "Максимальна кількість символів – {{ max_length }}",
  "forms.errors.message.new_password": "Новий пароль мусить співпадати в обох графах",
  "forms.errors.message.postal-code": "Неправильний індекс",
  "forms.errors.message.register_email_not_match": "Адреса електронної пошти не співпадає із адресою особи, що бронює",
  "forms.errors.message.required": "Необхідно заповнити цю графу",
  "forms.errors.message.words": "У полі виявлено недозволені символи",
  "booking.banner.information.two-way": "зворотна",
  "booking.banner.information.one-way": "в один бік",
  "booking.banner.btn.edit-search": "Редагувати пошук",
  "booking.depart.title": "Забронювати першу поїздку",
  "booking.return.title": "Забронюйте зворотну поїздку",
  "booking.calendar.no-connections": "Сполучення відсутні",
  "booking.calendar.price-from": "з",
  "booking.details.transits.title": "Доступні сполучення",
  "booking.details.search.title": "Деталі пошуку",
  "booking.details.search.address.depart": "З адреси",
  "booking.details.search.address.return": "на адресу",
  "booking.transit.pin.from.bus-stop": "Із зупинки",
  "booking.transit.pin.from.point": "З адреси",
  "booking.transit.pin.to.bus-stop": "на зупинку",
  "booking.transit.pin.to.point": "за адресою",
  "booking.transit.summary.title": "Всього за проїзд",
  "btn.change": "Змінити",
  "btn.book": "Бронювати",
  "booking.transit.price.per-person": "Ціна за особу",
  "btn.booking-avaialble-count": "Доступні місця: {{ count }}",
  "btn.limit-reached": "Місця відсутні",
  "btn.edit-reservation": "Змінити бронювання",
  "btn.transit.show-details": "Покажіть деталі поїздки",
  "booking.transit.selected.form.address.incomplete": "Щоб забронювати, вкажіть назву вулиці та номер будинку",
  "btn.continue": "Далі",
  "nav.hello": "Привіт",
  "account.login.register.description.1": "Зареєструйтеся через форму та залишайтеся на звʼязку із нами!",
  "account.login.register.description.2": "Памʼятайте, що завдяки реєстрації:",
  "account.login.register.list.1": "Отримаєте безкоштовний обліковий запис пасажира",
  "account.login.register.list.2": "Отримаєте доступ до найновіших акцій та пропозицій",
  "account.login.register.list.3": "Матимете повний огляд історії своїх замовлень",
  "account.login.register.title": "Ви ще не зареєстровані? Нічого страшного!",
  "booking.summary.total": "Всього за проїзд",
  "btn.booking.create-draft": "Введіть дані пасажира",
  "title.order.reservation-details": "Дані для бронювання",
  "title.order.additinal-options": "Додаткові послуги",
  "title.order.payment-methods": "Способи оплати",
  "order.customer.firstname.label": "Імʼя",
  "order.customer.lastname.label": "Прізвище",
  "order.customer.email.label": "Адреса електронної пошти:",
  "order.customer.phone.label": "Номер телефону",
  "order.customer.subtitle": "Дані особи, що бронює",
  "order.customer.invoice.subtitle": "Дані для інвойсу",
  "order.customer.invoice.type.person": "Приватна особа",
  "order.customer.invoice.type.company": "Компанія",
  "order.customer.invoice.information": "Повідомляємо, що згідно з чинним законодавством НЕМОЖЛИВО оформити рахунок-фактуру з ПДВ для компанії, якщо дані (включаючи  податковий номер NIP компанії) не надаються на етапі розміщення замовлення.",
  "order.customer.company-name.label": "Назва компанії",
  "order.customer.country.label": "Країна",
  "order.customer.street.label": "Вулиця і номер",
  "order.customer.postal-code.label": "Індекс",
  "order.customer.city.label": "Місто",
  "order.customer.nip.label": "Податковий номер NIP",
  "order.customer.nip.button": "Отримати дані з GUS",
  "order.customer.passenger.title": "{{ count }}. Пасажир",
  "order.customer.passenger.firstname.label": "Імʼя",
  "order.customer.passenger.lastname.label": "Прізвище",
  "order.customer.passenger.phone.label": "Номер телефону",
  "order.card.passenger.minor.label": "Неповнолітній",
  "order.card.passenger.minor.description.1": "Для дитини зростом до 150 см необхідно забезпечити дитяче крісло/бустер. ",
  "order.card.passenger.minor.description.2": "Дитина до 12 років може подорожувати лише з опікуном. ",
  "order.card.passenger.minor.description.3": "Дитина старше 12 років може подорожувати без опікуна лише після заповнення заяви.",
  "order.card.passenger.minor.description.3.1": "Завантажити заяву",
  "order.card.passenger.differ.label": "Хочу вказати інші дані пасажира (не особи, що бронює)",
  "order.customer.comment.title": "Зауваження для водія",
  "order.customer.comment.description": "Поставте прапорець, якщо ви хочете надати нам будь-яку важливу інформацію про ваше замовлення.",
  "order.customer.comment.checkbox.label": "Хоч додати зауваження для водія",
  "order.customer.comment.textarea.label": "Введіть зміст повідомлення",
  "order.options.2.title": "Додатковий багаж",
  "order.options.2.description": "Можливість додати додаткову одиницю багажу <b>до 20 кг</b> (для кожного пасажира).",
  "order.options.2.switch.label": "Додати додатковий багаж для пасажира",
  "order.options.2.box.1.title": "Багаж, що включений в ціну квитка на одного пасажира:",
  "order.options.2.box.list.1a.title": "1 шт. ручної поклажі",
  "order.options.2.box.list.1a.description": "до 5 кг",
  "order.options.2.box.list.1b.title": "1 шт. основного багажу",
  "order.options.2.box.list.1b.description": "максимум 2 одиниці багажу загальною вагою до 40 кг, ширина + висота + глибина: макс. 165 см",
  "order.options.2.box.2.title": "Нестандартний багаж*:",
  "order.options.2.box.list.2a.title": "Нестандартний багаж?",
  "order.options.2.box.list.2a.description": "Існує можливість перевезення <b>собак вагою до 15 кг, котів та невеликих домашніх тварин</b>, а також <b> ненормативного багажу</b>, для погодження деталей, зв'яжіться з нашою гарячою лінією за номером\n <b>+48 606 200 002</b>",
  "order.options.2.box.2.helptext": "* Після погодження із перевізником",
  "order.options.6.title": "Гарантія повернення ціни квитка",
  "order.options.6.switch.label": "Додати гарантію повернення квитка",
  "order.options.6.description": "Детальну інформацію про гарантію знайдете в Положенні",
  "order.option.depart.title": "Перша поїздка",
  "order.option.return.title": "Повернення",
  "order.option.route.from.title": "З: <b>{{ city }}</b>, {{ street }}",
  "order.option.route.to.title": "До: <b>{{ city }}</b>, {{ street }}",
  "order.option.route.passenger.name": "Пасажир {{ index }}",
  "order.payment.provider.description.payu-ap": "Платіж карткою, що збережена в записі Google Pay",
  "order.payment.provider.description.payu-blik": "Платіж кодом БЛІК",
  "order.payment.provider.description.payu-c": "Платіж після вказання даних банківської картки",
  "order.payment.provider.description.payu-dpp": "Відтермінування платежу на 30 днів",
  "order.payment.provider.description.payu-dpt": "Відтермінування платежу на 30 днів",
  "order.payment.provider.description.payu-jp": "Платіж карткою, що збережена в гаманці Apple Pay",
  "order.payment.provider.description.payu-pw": "Банківський переказ онлайн із польського банківського рахунку",
  "order.payment.provider.description.stripe-bancontact": "Банківський переказ онлайн із бельгійського банківського рахунку",
  "order.payment.provider.description.stripe-ideal": "Банківський переказ онлайн із нідерландського банківського рахунку",
  "order.payment.provider.description.transfer-transfer": "Банківський переказ на номер банківського рахунку",
  "order.payment.provider.title.payu-ap": "Google Pay",
  "order.payment.provider.title.payu-blik": "Blik",
  "order.payment.provider.title.payu-c": "Карткою",
  "order.payment.provider.title.payu-dpp": "Оплатити пізніше",
  "order.payment.provider.title.payu-dpt": "Twisto оплатити пізніше",
  "order.payment.provider.title.payu-jp": "Apple Pay",
  "order.payment.provider.title.payu-pw": "Миттєвий банківський переказ",
  "order.payment.provider.title.stripe-bancontact": "Bancontact",
  "order.payment.provider.title.stripe-ideal": "Ideal",
  "order.payment.provider.title.transfer-transfer": "Стандартний банківський переказ",
  "order.summary.title": "Ваше бронювання успішно",
  "order.option.type.1.negative.name": "квиток",
  "order.option.type.1.positive.name": "квиток",
  "order.option.type.2.negative.name": "додатковий багаж",
  "order.option.type.2.positive.name": "додатковий багаж",
  "order.option.type.3.negative.name": "додаткова послуга",
  "order.option.type.3.positive.name": "додаткова послуга",
  "order.option.type.4.negative.name": "посилка",
  "order.option.type.4.positive.name": "посилка",
  "order.option.type.6.negative.name": "гарантія повернення ціни квитка",
  "order.option.type.6.positive.name": "гарантія повернення ціни квитка",
  "order.summary.total.title.1": "Всього",
  "order.summary.total.title.2": "(з ПДВ)",
  "order.summary.discount.button.label": "У мене є купон на знижку",
  "btn.activate": "Активувати",
  "order.summary.form.discount.input.label": "Введіть код знижки",
  "order.summary.form.discount.info.text.1": "Знижку застосовано. Ціна раніше:",
  "order.summary.form.discount.info.text.2": ". Ви заощаджуєте",
  "btn.buy": "Придбати і оплатити",
  "order.summary.discount.applied.text": "Знижку застосовано правильно",
  "order.confirmation.success.title": "Ваше бронювання успішно завершено",
  "order.confirmation.success.description": "Підтвердження бронювання надішлемо за адресою, що вказана під час бронювання",
  "order.confirmation.fail.title": "Помилка платежу",
  "order.confirmation.fail.description": "Повторіть платіж у вкладці Мій обліковий запис / Мої бронювання або звʼяжіться з нашою гарячою лінією, тел. +48 606 200 002, електронна пошта: info@gtvbus.pl",
  "btn.back-to-main-page": "Повернутися на головну",
  "order.confirmation.success.bank-transfer.description.1": "Водночас повідомляємо, що умовою поїздки буде оплата.",
  "order.confirmation.success.bank-transfer.description.2": "Якщо вартість проїзду не буде сплачено, бронювання буде скасовано.",
  "order.confirmation.success.bank-transfer.details.1": "Просимо оплатити на рахунок:",
  "order.confirmation.success.bank-transfer.details.2": "GTV Office Sp. z o.o. ul. Hutnicza 5 46-040 Ozimek",
  "order.confirmation.success.bank-transfer.details.3.EUR": "Номер рахунку в євро:",
  "order.confirmation.success.bank-transfer.details.3.PLN": "Номер рахунку в злотих:",
  "order.confirmation.success.bank-transfer.details.4": "ING Bank Śląski",
  "order.confirmation.success.bank-transfer.details.5": "SWIFT KOD: INGBPLPW",
  "order.confirmation.success.bank-transfer.details.6": "Детальну інформацію про суму та призначення переказу можете знайти в отриманому електронному листі",
  "order.confirmation.success.bank-transfer.details.7": "Умовою проведення платежу є надсилання підтвердження переказу на адресу електронної пошти: <a href=\"mailto:przelew@gtvbus.pl\">przelew@gtvbus.pl</a>",
  "order.confirmation.success.bank-transfer.number.EUR": "23 1050 1504 1000 0090 8133 6084",
  "order.confirmation.success.bank-transfer.number.PLN": "45 1050 1504 1000 0090 8133 6076",
  "btn.register": "Зареєструватися",
  "account.register.title": "Зареєструватися",
  "account.register.already-registered": "Ви вже зареєстровані?",
  "account.register.form.login-now": "Увійти!",
  "account.request-reset-password.description": "Щоб відновити пароль, введіть свою адресу електронної пошти, яку ви вказали при реєстрації. Протягом миті отримаєте посилання для відновлення паролю. Якщо повідомлення не буде у теці „Вхідні”, перевірите інші теки у своїй скриньці, напр. Спам, Пропозиції, Сповіщення тощо.",
  "account.request-reset-password.success.description": "Перевірте електронну пошту, щоб знайти посилання для відновлення паролю. Якщо не прийде протягом декількох хвилин, перевірте теку зі спамом. ",
  "account.request-reset-password.success.title": "Відновити пароль",
  "account.request-reset-password.title": "Відновити пароль",
  "btn.request-password-reset": "Надіслати посилання",
  "account.reset-password.form.password": "Пароль",
  "account.reset-password.title": "Введіть новий пароль",
  "order.options.5.title": "Гарантія повернення ціни квитка",
  "order.options.5.switch.label": "Додати гарантію повернення квитка",
  "order.options.5.description": "Детальну інформацію про гарантію знайдете в Положенні",
  "language.select.pl-PL": "Польська",
  "language.select.ua-UA": "Українська",
  "calendar.mixed_ride.info.1": "Поїздка відбувається таким чином: бус-автобус-бус",
  "calendar.mixed_ride.info.2": "Детальна інформація на сайті <a class=\"tw-text-primary tw-underline\" href=\"https://gtvbus.pl/poranne-przejazdy/\" target=\"_blank\" > www.gtvbus.pl/poranne-przejazdy</a>",
  "search.widget.form.calendar-range.one-way.label": "Відправлення",
  "search.widget.form.calendar-range.two-way.label": "Дати подорожі",
  "search.widget.form.calendar-range.one-way.placeholder": "Вибрати дату",
  "search.widget.form.calendar-range.two-way.placeholder": "Вибрати дати",
  "btn.back": "Повернення",
  "btn.remove-selection": "Прибрати",
  "search.widget.form.calendar-range.popup.select-depart-date": "Вибрати дату подорожі",
  "search.widget.form.calendar-range.popup.depart": "Відправлення",
  "search.widget.form.calendar-range.popup.select-return-date": "Вибрати дату зворотної подорожі",
  "search.widget.form.calendar-range.popup.return": "Назад",
  "btn.confirm-selection": "Підтвердити вибрані",
  "btn.edit": "Редагувати",
  "booking.banner.information.1": "{{ value }} пасажир",
  "booking.banner.information.x": "{{ value }} пасажирів",
  "order.transit.selected.depart.title": "Вибраний перший проїзд",
  "order.transit.selected.return.title": "Вибраний другий проїзд",
  "account.login.form.forgot-password-2": "Я не памʼятаю пароль",
  "account.login.form.not-registered": "Ще не зареєструвалися?",
  "account.login.form.register-now": "Створіть обліковий запис!",
  "account.login.register.list.description.1": "Наступні бронювання будуть набагато зручнішими, без необхідності заповнювати свої дані повторно. Ви також отримаєте доступ до неповторних переваг.",
  "account.login.register.list.description.2": "Лише зареєстровані користувачі можуть скористатися багатьма привабливими перевагами, такими як дешевші подорожі та привабливі акції чи конкурси.",
  "account.login.register.list.description.3": "Ви можете легко знайти дати та ціни ваших поїздок, перевірити рахунок або здійснити оплату.",
  "account.register.why-join": "Чому ",
  "booking.edit.title": "Редагувати пошук",
  "btn.change-password": "Змінити пароль",
  "btn.close": "Закрити",
  "btn.create-account": "Створити обліковий запис",
  "btn.edit-close": "Закрити редагування",
  "btn.edit-search": "Редагувати пошук",
  "btn.save": "Зберегти",
  "btn.set-password": "Встановіть пароль",
  "forms.errors.message.invalidPhoneNumber": "Вкажіть правильний номер телефону",
  "forms.errors.message.must-match": "Ці графи мусять співпадати",
  "forms.errors.message.phone-number": "Вкажіть правильний номер телефону",
  "forms.errors.message.text": "Неправильне значення",
  "forms.errors.message.words-numbers": "Вміст поля має містити лише цифри та літери",
  "menu.mobile.login": "Увійти",
  "menu.mobile.panel": "Мій обліковий запис",
  "mobile.menu.item.contact": "Звʼязок",
  "mobile.menu.item.help": "Допомога",
  "mobile.menu.item.language": "Мова і валюта",
  "mobile.menu.item.language.select-currency": "Вибрати валюту",
  "mobile.menu.item.language.select-language": "Вибрати мову",
  "mobile.menu.item.language.title": "Змінити мову і валюту",
  "mobile.menu.item.local-timetable": "Місцевий розклад руху",
  "mobile.menu.item.local-timetable.czestochowa": "Ченстохова",
  "mobile.menu.item.local-timetable.gliwice": "Ґлівіце",
  "mobile.menu.item.local-timetable.kedzierzyn-kozle": "Кендзєжин-Козьлє",
  "mobile.menu.item.local-timetable.opole": "Ополе",
  "mobile.menu.item.local-timetable.title": "Місцевий розклад руху",
  "mobile.menu.item.luggage": "Багаж",
  "mobile.menu.item.reservation": "Як забронювати?",
  "nav.faq": "FAQ",
  "order.customers.list.details.title": "Деталі бронювання",
  "order.customers.list.invoice.title": "Реквізити рахунку-фактури",
  "order.register.login.btn": "авторизуватися",
  "order.register.login.text": "Вже маєте акаунт?",
  "order.register.title": "Відстежуйте свої поїздки за допомогою GTV Bus.",
  "forms.errors.message.maxlength": "Значення поля задовге",
  "busstop-info.title": "Шановний клієнте,",
  "busstop-info.content-1": "Зверніть увагу, що поїздка, яку ви бронюєте, призначена для подорожі автобусом від зупинки до зупинки. Перевірте деталі свого маршруту та насолоджуйтеся комфортною подорожжю!",
  "busstop-info.content-2": "Дякуємо, що обираєте наші послуги!"
}
