import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { HeremapsGeoreverseActions } from '../actions/georeverse.actions';
import { HttpService } from '../services/http.service';

@Injectable()
export class HeremapsGeoreverseEffects {
    getHeremapsGeoreverse$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(HeremapsGeoreverseActions.getHeremapsGeoreverseAction),
            mergeMap((action) =>
                this.httpService.reverseGeocode(action.lat.toString(), action.lng.toString()).pipe(
                    map((item) =>
                        HeremapsGeoreverseActions.getHeremapsGeoreverseSuccessAction({
                            lng: action.lng,
                            lat: action.lat,
                            payload: item,
                        }),
                    ),
                    catchError(() =>
                        of(
                            HeremapsGeoreverseActions.getHeremapsGeoreverseErrorAction({
                                lng: action.lng,
                                lat: action.lat,
                            }),
                        ),
                    ),
                ),
            ),
        );
    });

    getHeremapsGeoreverseSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(ofType(HeremapsGeoreverseActions.getHeremapsGeoreverseSuccessAction));
        },
        {
            dispatch: false,
        },
    );

    getHeremapsGeoreverseError$ = createEffect(
        () => {
            return this.actions$.pipe(ofType(HeremapsGeoreverseActions.getHeremapsGeoreverseErrorAction));
        },
        {
            dispatch: false,
        },
    );

    constructor(
        private actions$: Actions,
        private httpService: HttpService,
    ) {}
}
