import { Order } from '@app/store/order';
import { CURRENCY } from '@currency';

export class OrderUpdateGTM {
    event = 'cart_summary';
    currency: CURRENCY;
    value: number;
    items: { item_id: string | null; item_name: string; price: number }[];

    constructor(order: Order) {
        const items = order.transactions.map((transactions) => transactions.items).flat();
        // currencyt
        this.currency = order.payments.find((payment) => order.transactions[0].paymentId === payment.id)?.currency ?? order.transactions[0].currency;
        // price
        this.value = items.map((item) => item.price[this.currency] ?? 0).reduce((sum, price) => sum + price, 0) / 100;
        // items
        this.items = items.map((item) => ({
            item_id: item.id,
            item_name: item.name,
            price: (item.price[this.currency] ?? 0) / 100,
        }));
    }
}
