import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CountriesState } from '../reducers/countries.reducer';
import { COUNTRIES_FEATURE_KEY } from '../keys';
import { COUNTRY } from '@app/shared/classes';

const selectState = createFeatureSelector<CountriesState>(COUNTRIES_FEATURE_KEY);

export const selectLoaded = createSelector(selectState, (state: CountriesState) => state.loaded);
export const selectCountries = createSelector(selectState, (state: CountriesState) => state.countries);
export const selectCountriesAlpha2 = createSelector(selectState, (state: CountriesState) => state.countries.map((country) => country.id));
export const selectCountriesAlpha3 = createSelector(selectState, (state: CountriesState) => state.countries.map((country) => COUNTRY.code2to3(country.id)));
