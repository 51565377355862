import { inject, Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { UserFacade } from '@app/store/user';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class UserPreloadGuard {
    private readonly userFacade = inject(UserFacade);

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.userFacade.loaded$.pipe(
            tap((loaded) => {
                if (loaded === null) {
                    this.userFacade.loadUser();
                }
            }),
            filter((loaded) => loaded !== null),
            take(1),
            map(() => true),
        );
    }
}
