import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { HeremapsLookupActions } from '../actions/lookup.actions';
import { HttpService } from '../services/http.service';

@Injectable()
export class HeremapsLookupEffects {
    getHeremapsLookup$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(HeremapsLookupActions.getHeremapsLookupAction),
            mergeMap((action) =>
                this.httpService.getLookup(action.id).pipe(
                    map((data) =>
                        HeremapsLookupActions.getHeremapsLookupSuccessAction({
                            id: action.id,
                            payload: data,
                        }),
                    ),
                    catchError(() => of(HeremapsLookupActions.getHeremapsLookupErrorAction({ id: action.id }))),
                ),
            ),
        );
    });

    getHeremapsLookupSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(ofType(HeremapsLookupActions.getHeremapsLookupSuccessAction));
        },
        {
            dispatch: false,
        },
    );

    getHeremapsLookupError$ = createEffect(
        () => {
            return this.actions$.pipe(ofType(HeremapsLookupActions.getHeremapsLookupErrorAction));
        },
        {
            dispatch: false,
        },
    );

    constructor(
        private actions$: Actions,
        private httpService: HttpService,
    ) {}
}
