<div #focusEl class="box" tabindex="0" (focusin)="onFocusIn()" (focusout)="onFocusOut()">
    <div class="field">
        @if (type === 'language') {
            <div class="flag" [ngStyle]="{ 'background-image': getFlagUrl(value$ | async) }"></div>
        } @else {
            <span class="value">{{ value$ | async }}</span>
        }
        <app-icon icon="caret" [class.tw-rotate-90]="(focus$ | async) === false" [class.-tw-rotate-90]="(focus$ | async) === true" [size]="24" />
    </div>

    @if (focus$ | async) {
        <app-dropdown-list @Grow [options]="options" [type]="type" (selectEvent)="onSelectEvent($event)" />
    }
</div>

<div class="tw-hidden" #blurEl tabindex="-1"></div>
