import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { HereGeocodeModel } from '../models';

export const HeremapsAutosuggestActions = createActionGroup({
    source: 'HeremapsAutosuggest',
    events: {
        'Get Heremaps Autosuggest Action': props<{ term: string; countries: string[]; skipCountryCode: string }>(),
        'Get Heremaps Autosuggest Success Action': props<{ term: string; skipCountryCode: string; payload: HereGeocodeModel[] }>(),
        'Get Heremaps Autosuggest Error Action': props<{ term: string; skipCountryCode: string }>(),
        'Clear Heremaps Autosuggest Action': emptyProps(),
    },
});
