import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MaintenanceContainerComponent, MaintenanceDisabledGuard, MaintenanceEnabledGuard } from './maintenance';

import { ShellComponent } from './shell/components/shell/shell.component';
import { parcelPaymentRedirect } from './redirects';
import { orderCleanup } from './store/order';
import { orderSegmentCleanup } from './store/order-segment';
import { showSettings, hideSettings, UserPreloadGuard } from './shell/shared/guards';
import { countriesLoaded } from './store/countries';

const routes: Routes = [
    {
        path: '',
        canActivateChild: [MaintenanceEnabledGuard],
        children: [
            {
                path: '',
                canActivate: [countriesLoaded()],
                children: [
                    {
                        path: '',
                        redirectTo: 'booking',
                        pathMatch: 'full',
                    },
                    {
                        path: '',
                        component: ShellComponent,
                        canActivate: [UserPreloadGuard],
                        children: [
                            {
                                path: 'home',
                                pathMatch: 'full',
                                redirectTo: '',
                            },
                            {
                                path: '',
                                loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
                            },
                            {
                                path: 'booking/passenger',
                                loadChildren: () => import('./booking/booking.module').then((m) => m.BookingModule),
                            },
                            {
                                path: 'booking/details',
                                canActivate: [hideSettings()],
                                canDeactivate: [orderCleanup(), showSettings()],
                                loadChildren: () => import('./booking-details/booking-details.module').then((m) => m.BookingDetailsModule),
                            },
                            {
                                path: 'booking/confirmation',
                                canDeactivate: [orderSegmentCleanup()],
                                loadChildren: () => import('./booking-response/booking-response.module').then((m) => m.BookingResponseModule),
                            },
                            {
                                path: 'booking/parcel/payment/:bookingId/:secret',
                                redirectTo: parcelPaymentRedirect,
                            },
                            {
                                path: 'r',
                                loadChildren: () => import('./remarketing/remarketing.module').then((m) => m.RemarketingModule),
                            },
                            {
                                path: 'user',
                                loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
                            },
                            {
                                path: 'booking/closest',
                                loadChildren: () => import('./booking-closest/booking-closest.module').then((m) => m.BookingClosestModule),
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        path: 'maintenance',
        component: MaintenanceContainerComponent,
        canActivate: [MaintenanceDisabledGuard],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: false })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
