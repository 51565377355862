import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HeremapsGeoreverseState } from '../reducers/georeverse.reducer';
import { HEREMAPS_GEOREVERSE_FEATURE_KEY } from '../keys';

const selectHeremapsGeoreverseState = createFeatureSelector<HeremapsGeoreverseState>(HEREMAPS_GEOREVERSE_FEATURE_KEY);

export const selectHeremapsGeoreverseItem = (lat: number, lng: number) =>
    createSelector(selectHeremapsGeoreverseState, (state: HeremapsGeoreverseState) => {
        const index = state.data.findIndex((item) => item.lat === lat && item.lng === lng);
        return index !== -1 ? state.data[index].item : null;
    });

export const selectHeremapsGeoreverseLoading = (lat: number, lng: number) =>
    createSelector(selectHeremapsGeoreverseState, (state: HeremapsGeoreverseState) => {
        const index = state.data.findIndex((item) => item.lat === lat && item.lng === lng);
        return index !== -1 ? state.data[index].loading : null;
    });

export const selectHeremapsGeoreverse = (lat: number, lng: number) =>
    createSelector(selectHeremapsGeoreverseState, (state: HeremapsGeoreverseState) => {
        const index = state.data.findIndex((item) => item.lat === lat && item.lng === lng);
        return index !== -1 ? state.data[index] : null;
    });

export const selectHeremapsGeoreverseLoadingAnything = createSelector(selectHeremapsGeoreverseState, (state: HeremapsGeoreverseState) => {
    return Object.values(state.data).some((item) => item.loading === true);
});
