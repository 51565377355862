import { inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { LanguageService } from '@app/i18n';

@Injectable({
    providedIn: 'root',
})
export class ApiPrefixServerInterceptor implements HttpInterceptor {
    private languageService = inject(LanguageService);

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (!/^(http|https):/i.test(request.url) && !/assets\/i18n\//i.test(request.url)) {
            request = request.clone({
                url: environment.serverUrl + request.url,
                headers: request.headers.append('X-Gtv-Language', this.languageService.get()),
            });
        }
        return next.handle(request);
    }
}
