import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PopupComponent } from '@app/shared/components';

@Component({
    selector: 'app-reset-password-confirm',
    templateUrl: './reset-password-confirm.component.html',
    styleUrls: ['./reset-password-confirm.component.scss'],
    standalone: true,
    imports: [CommonModule, TranslateModule, PopupComponent],
})
export class ResetPasswordConfirmComponent {}
