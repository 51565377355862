import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import {
    HEREMAPS_AUTOSUGGEST_FEATURE_KEY,
    HEREMAPS_LOOKUP_FEATURE_KEY,
    HEREMAPS_CLOSEST_FEATURE_KEY,
    HEREMAPS_GEOREVERSE_FEATURE_KEY,
    HEREMAPS_NEAREST_FEATURE_KEY,
} from './keys';
import { heremapsLookupReducer } from './reducers/lookup.reducer';
import { HeremapsLookupEffects } from './effects/lookup.effects';
import { heremapsAutosuggestReducer } from './reducers/autosuggest.reducer';
import { HeremapsAutosuggestEffects } from './effects/autosuggst.effects';
import { heremapsClosestReducer } from './reducers/closest.reducer';
import { HeremapsClosestEffects } from './effects/closest.effects';
import { heremapsGeoreverseReducer } from './reducers/georeverse.reducer';
import { HeremapsGeoreverseEffects } from './effects/georeverse.effects';
import { heremapsNearestReducer } from './reducers/nearest.reducer';
import { HeremapsNearestEffects } from './effects/nearest.effects';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(HEREMAPS_LOOKUP_FEATURE_KEY, heremapsLookupReducer),
        StoreModule.forFeature(HEREMAPS_AUTOSUGGEST_FEATURE_KEY, heremapsAutosuggestReducer),
        StoreModule.forFeature(HEREMAPS_CLOSEST_FEATURE_KEY, heremapsClosestReducer),
        StoreModule.forFeature(HEREMAPS_GEOREVERSE_FEATURE_KEY, heremapsGeoreverseReducer),
        StoreModule.forFeature(HEREMAPS_NEAREST_FEATURE_KEY, heremapsNearestReducer),
        EffectsModule.forFeature([
            HeremapsLookupEffects,
            HeremapsAutosuggestEffects,
            HeremapsClosestEffects,
            HeremapsGeoreverseEffects,
            HeremapsNearestEffects,
        ]),
    ],
})
export class HeremapsStoreModule {}
