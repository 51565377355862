<div class="list">
    @for (item of items; track item.code) {
        <div>
            <button type="button" (click)="selectValueEvent.next(item.code)">
                <img src="assets/svgs-optimized-flags/{{ item.country }}.svg" />
                {{ item.code }}
            </button>
        </div>
    }
</div>
