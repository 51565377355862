import { inject, Injectable } from '@angular/core';
import { BookingStaticItemsModel, BookingTransactionItemModel } from '@app/store/booking/models';
import { Order } from '@app/store/order';
import { environment } from '@env/environment';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { OrderSegment } from '@app/store/order-segment';
import { BeginCheckoutGTM, OrderUpdateGTM, PurchaseGTM } from '../models';

@Injectable({
    providedIn: 'root',
})
export class GtmTrackingService {
    private gtmService = inject(GoogleTagManagerService);

    pageView(url: string) {
        if (this.enabled) {
            this.gtmService.pushTag({ event: 'APP Page View', pageName: url });
        }
    }

    purchase(order: OrderSegment) {
        const value = new PurchaseGTM(order);
        if (this.enabled && (<PerformanceNavigationTiming>window.performance.getEntries()[0]).type !== 'reload') {
            this.gtmService.pushTag(value);
        }
    }

    beginCheckout(order: Order) {
        const value = new BeginCheckoutGTM(order);
        if (this.enabled) {
            this.gtmService.pushTag(value);
        }
    }

    cartSummaryUpdate(order: Order) {
        const value = new OrderUpdateGTM(order);
        if (this.enabled) {
            this.gtmService.pushTag(value);
        }
    }

    private get enabled() {
        return environment.integrations.google.tagManager.enabled && environment.integrations.google.tagManager.id;
    }

    // #DEPRECATED
    beginCheckoutEvent(value: number, currency: string, items: BookingTransactionItemModel[][]) {
        if (this.enabled) {
            const gtmTag = {
                event: 'begin_checkout',
                value: value,
                currency: currency,
                items: [...items[0].map((item) => ({ item_id: item.id, item_name: item.name }))],
            };
            this.gtmService.pushTag(gtmTag);
        }
    }

    // #DEPRECATED
    cartSummaryChangeEvent(currency: string, value: number, items: { item_id: string; item_name: string }[]) {
        if (this.enabled) {
            const gtmTag = {
                event: 'cart_summary',
                currency: currency,
                value: value,
                items: items,
            };
            this.gtmService.pushTag(gtmTag);
        }
    }

    // #DEPRECATED
    purchaseEvent(transactionId: string, currency: string, value: number, email: string, items: BookingStaticItemsModel[]) {
        if (this.enabled) {
            const gtmTag = {
                event: 'purchase',
                transaction_id: transactionId,
                currency: currency,
                value: value / 100,
                email: email,
                items: items.map((item) => ({ item_id: item.id, item_name: item.name, item_price: item.price / 100, item_currency: item.currency })),
            };
            if ((<PerformanceNavigationTiming>window.performance.getEntries()[0]).type !== 'reload') {
                this.gtmService.pushTag(gtmTag);
            }
        }
    }
}
