import { createReducer, on } from '@ngrx/store';
import { tickerActions } from '../actions/ticker.actions';
import { Message } from '../models';

export interface TickerState {
    messages: Message[] | null;
    loaded: boolean | null;
    loading: boolean | null;
}

export const initialState: TickerState = {
    messages: null,
    loaded: false,
    loading: false,
};

export const tickerReducer = createReducer(
    initialState,
    on(
        tickerActions.get,
        (state): TickerState => ({
            ...state,
            loaded: false,
            loading: true,
        }),
    ),
    on(
        tickerActions.getSuccess,
        (state, payload): TickerState => ({
            ...state,
            messages: payload.value,
            loaded: true,
            loading: false,
        }),
    ),
    on(
        tickerActions.getError,
        (state): TickerState => ({
            ...state,
            loaded: false,
            loading: false,
        }),
    ),
    on(
        tickerActions.clear,
        (state): TickerState => ({
            ...state,
            ...initialState,
        }),
    ),
);
