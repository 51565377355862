import { inject, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { interval, of } from 'rxjs';
import { catchError, exhaustMap, map, startWith } from 'rxjs/operators';
import { tickerActions } from '../actions/ticker.actions';
import { HttpService } from '../services/http.service';

@Injectable()
export class TickerEffects {
    private actions = inject(Actions);
    private httpService = inject(HttpService);

    get$ = createEffect(() => {
        return this.actions.pipe(
            ofType(tickerActions.get),
            exhaustMap(() =>
                this.httpService.messages().pipe(
                    map((value) => tickerActions.getSuccess({ value })),
                    catchError(() => of(tickerActions.getError())),
                ),
            ),
        );
    });

    getInterval$ = createEffect(() => {
        return interval(environment.ticker.interval.request * 1000).pipe(
            startWith(0),
            map(() => tickerActions.get()),
        );
    });
}
