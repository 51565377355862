import { AfterViewInit, Directive, ElementRef, HostListener, inject } from '@angular/core';

@Directive({
    selector: '[appFocusTrap]',
    standalone: true,
})
export class FocusableElementsDirective implements AfterViewInit {
    private readonly elemnentRef = inject(ElementRef);

    @HostListener('document:keydown.tab', ['$event']) onTab = this.preventUserFromTabbingOut;
    @HostListener('document:keydown.shift.tab', ['$event']) onShiftTab = this.preventUserFromTabbingOut;

    ngAfterViewInit(): void {
        this.focusFirstFocusableElement();
    }

    private focusFirstFocusableElement() {
        let focusableElements = [];
        focusableElements = this.getFocusableElements();
        if (focusableElements[0]) {
            console.log(focusableElements[0]);
            focusableElements[0].focus();
        }
    }

    private getFocusableElements() {
        const focusableElementsQuery =
            'input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), *[role=button], [tabindex]';
        const elements = [...this.elemnentRef.nativeElement.querySelectorAll(focusableElementsQuery)];

        const elementsNew: HTMLInputElement[] = [];
        elements.forEach((element: HTMLInputElement) => {
            if (
                (element.classList.contains('ps__thumb-y') && element.offsetWidth === 0) ||
                (element.classList.contains('ps__thumb-x') && element.offsetWidth === 0)
            )
                return;

            elementsNew.push(element);
        });
        return elementsNew;
    }

    private preventUserFromTabbingOut(keyEvent: KeyboardEvent) {
        const activeElement = document.activeElement;

        const firstTabbableElement = this.getFirstFocusableElement();
        const lastTabbableElement = this.getLastFocusableElement();
        const shiftPressed = keyEvent.shiftKey;

        if (shiftPressed && activeElement === firstTabbableElement) {
            lastTabbableElement.focus();
            keyEvent.preventDefault();
        }

        if (!shiftPressed && activeElement === lastTabbableElement) {
            firstTabbableElement.focus();
            keyEvent.preventDefault();
        }
    }

    private getFirstFocusableElement() {
        return this.getFocusableElements()[0];
    }

    private getLastFocusableElement() {
        const focusableElements = this.getFocusableElements();
        return focusableElements[focusableElements.length - 1];
    }
}
