import { createReducer, on } from '@ngrx/store';

import { HeremapsClosestActions } from '../actions/closest.actions';
import { HereGeocodeModel } from '../models';

export interface HeremapsClosestState {
    data: {
        term: string;
        city: string;
        countryCode: string;
        lat: number;
        lng: number;
        loading: boolean;
        item?: HereGeocodeModel | null;
    }[];
}

export const initialState: HeremapsClosestState = {
    data: [],
};

export const heremapsClosestReducer = createReducer(
    initialState,
    on(HeremapsClosestActions.getHeremapsClosestAction, (state, action): HeremapsClosestState => {
        return {
            ...state,
            data: [
                ...state.data,
                {
                    term: action.term,
                    city: action.city,
                    countryCode: action.countryCode,
                    lat: action.lat,
                    lng: action.lng,
                    loading: true,
                    item: null,
                },
            ],
        };
    }),
    on(HeremapsClosestActions.getHeremapsClosestSuccessAction, (state, action): HeremapsClosestState => {
        const data = [...state.data];
        const itemIndex = data.findIndex(
            (item) =>
                item.term === action.term &&
                item.city === action.city &&
                item.countryCode === action.countryCode &&
                item.lat === action.lat &&
                item.lng === action.lng,
        );
        data[itemIndex] = {
            ...data[itemIndex],
            item: action.payload,
            loading: false,
        };
        return { data };
    }),
    on(HeremapsClosestActions.getHeremapsClosestErrorAction, (state, action): HeremapsClosestState => {
        const data = [...state.data];
        const itemIndex = data.findIndex(
            (item) =>
                item.term === action.term &&
                item.city === action.city &&
                item.countryCode === action.countryCode &&
                item.lat === action.lat &&
                item.lng === action.lng,
        );
        data[itemIndex] = {
            ...data[itemIndex],
            loading: false,
        };
        return { data };
    }),
    on(
        HeremapsClosestActions.clearClosestAction,
        (state): HeremapsClosestState => ({
            ...state,
            ...initialState,
        }),
    ),
);
