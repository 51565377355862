import { Observable } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';

import * as plPL from '../../../translations/pl-PL.json';
import * as uaUA from '../../../translations/ua-UA.json';

export class TranslateLoaderStatic implements TranslateLoader {
    getTranslation(lang: string): Observable<Record<string, string>> {
        return new Observable((observer) => {
            switch (lang) {
                case 'pl-PL':
                    observer.next(plPL);
                    break;
                case 'ua-UA':
                    observer.next(uaUA);
                    break;
            }
            observer.complete();
        });
    }
}
