import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { AccountFacade } from '@app/store/account';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, Subscription, tap } from 'rxjs';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent, PopupComponent } from '@app/shared/components';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss'],
    standalone: true,
    imports: [CommonModule, TranslateModule, ButtonComponent, PopupComponent],
})
export class LogoutComponent implements OnInit, OnDestroy {
    private readonly router = inject(Router);
    private readonly activatedRoute = inject(ActivatedRoute);
    private readonly accountFacade = inject(AccountFacade);

    private subscriptions$ = new Subscription();

    ngOnInit(): void {
        this.subscriptions$.add(
            this.accountFacade.loggedOut$
                .pipe(
                    filter((loggedOut) => loggedOut === true),
                    tap(() => this.router.navigate(['/'])),
                )
                .subscribe(),
        );
    }

    ngOnDestroy() {
        this.subscriptions$.unsubscribe();
        this.accountFacade.clear();
    }

    onSubmit(): void {
        this.accountFacade.logout();
    }

    onLogoutClick() {
        this.accountFacade.logout();
    }

    onCloseClick() {
        this.router.navigate(['../..'], { relativeTo: this.activatedRoute });
    }
}
