import { createReducer, on } from '@ngrx/store';

import { HeremapsLookupActions } from '../actions/lookup.actions';
import { HereGeocodeModel } from '../models';

export interface HeremapsLookupState {
    items: Record<
        string,
        {
            loading: boolean;
            item: HereGeocodeModel | null;
            error: number | null;
        }
    >;
}

export const initialState: HeremapsLookupState = {
    items: {},
};

export const heremapsLookupReducer = createReducer(
    initialState,
    on(
        HeremapsLookupActions.getHeremapsLookupAction,
        (state, action): HeremapsLookupState => ({
            ...state,
            items: {
                ...state.items,
                [action.id]: {
                    loading: true,
                    item: null,
                    error: null,
                },
            },
        }),
    ),
    on(
        HeremapsLookupActions.getHeremapsLookupSuccessAction,
        (state, action): HeremapsLookupState => ({
            ...state,
            items: {
                ...state.items,
                [action.id]: {
                    loading: false,
                    item: action.payload,
                    error: null,
                },
            },
        }),
    ),
    on(
        HeremapsLookupActions.getHeremapsLookupErrorAction,
        (state, action): HeremapsLookupState => ({
            ...state,
            items: {
                ...state.items,
                [action.id]: {
                    loading: false,
                    item: null,
                    error: 404,
                },
            },
        }),
    ),
    on(
        HeremapsLookupActions.clearHeremapsLookupAction,
        (state): HeremapsLookupState => ({
            ...state,
            ...initialState,
        }),
    ),
);
