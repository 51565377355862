import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, tap, map, take } from 'rxjs/operators';

import { HeremapsGeoreverseActions } from '../actions/georeverse.actions';
import { selectHeremapsGeoreverseLoading, selectHeremapsGeoreverse, selectHeremapsGeoreverseLoadingAnything } from '../selectors/georeverse.selector';

@Injectable({
    providedIn: 'root',
})
export class HeremapsGeoreverseFacade {
    private store = inject(Store);

    item$ = (lat: number, lng: number) =>
        this.store.select(selectHeremapsGeoreverse(lat, lng)).pipe(
            tap((data) => {
                if (data === null) {
                    this.georeverse(lat, lng);
                }
            }),
            filter((data) => {
                return data !== null && data.loading === false;
            }),
            map((data) => (data?.item ? data?.item : null)),
        );

    loading$ = (lat: number, lng: number) => this.store.select(selectHeremapsGeoreverseLoading(lat, lng)).pipe(filter((data) => data !== null));
    loadingAnything$ = this.store.select(selectHeremapsGeoreverseLoadingAnything);

    get loadingAnything() {
        let loading = false;

        this.store
            .select(selectHeremapsGeoreverseLoadingAnything)
            .pipe(
                take(1),
                tap((value) => (loading = value)),
            )
            .subscribe();

        return loading;
    }

    georeverse(lat: number, lng: number): void {
        this.store.dispatch(HeremapsGeoreverseActions.getHeremapsGeoreverseAction({ lat, lng }));
    }

    clear(): void {
        this.store.dispatch(HeremapsGeoreverseActions.clearHeremapsGeoreverse());
    }
}
