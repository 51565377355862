import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, map, take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { HeremapsLookupActions } from '../actions/lookup.actions';
import { HeremapsLookupState } from '../reducers/lookup.reducer';
import {
    selectHeremapsLookup,
    selectHeremapsLookupLoading,
    selectHeremapsLookupLoadingAnything,
    selectHeremapsLookupState,
} from '../selectors/lookup.selector';
import { HereGeocodeModel } from '../models';

@Injectable({
    providedIn: 'root',
})
export class HeremapsLookupFacade {
    constructor(private store: Store<HeremapsLookupState>) {}

    getAddress(id: string): void {
        this.store.dispatch(HeremapsLookupActions.getHeremapsLookupAction({ id: id }));
    }

    get items$(): Observable<HeremapsLookupState> {
        return this.store.select(selectHeremapsLookupState);
    }

    item$(id: string): Observable<HereGeocodeModel> {
        return this.store.select(selectHeremapsLookup(id)).pipe(
            filter(() => id !== ''),

            tap((data) => {
                if (data === null) {
                    this.getAddress(id);
                }
            }),
            filter((data) => {
                return data !== null && data.loading === false;
            }),
            map((data) => data?.item),
            filter((data): data is HereGeocodeModel => data !== null),
        );
    }

    error$(id: string): Observable<number> {
        return this.store.select(selectHeremapsLookup(id)).pipe(
            filter((data) => {
                return data !== null && data.loading === false;
            }),
            // eslint-disable-next-line @ngrx/avoid-mapping-selectors
            map((data) => data?.error),
            filter((data): data is number => data !== null),
        );
    }

    loading$(id: string): Observable<boolean> {
        return this.store.select(selectHeremapsLookupLoading(id)).pipe(filter((data) => data !== null));
    }

    get loadingAnything$(): Observable<boolean> {
        return this.store.select(selectHeremapsLookupLoadingAnything);
    }

    get loadingAnything() {
        let loading = false;

        this.store
            .select(selectHeremapsLookupLoadingAnything)
            .pipe(
                take(1),
                tap((value) => (loading = value)),
            )
            .subscribe();

        return loading;
    }

    clear(): void {
        this.store.dispatch(HeremapsLookupActions.clearHeremapsLookupAction());
    }
}
