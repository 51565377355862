import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { HeremapsNearestActions } from '../actions/nearest.actions';
import { HttpService } from '../services/http.service';
import { environment } from '@env/environment';
import { concatLatestFrom } from '@ngrx/operators';
import { selectHeremapsNearestEntryExists } from '../selectors/nearest.selector';
import { Store } from '@ngrx/store';

@Injectable()
export class HeremapsNearestEffects {
    private store = inject(Store);
    private actions$ = inject(Actions);
    private httpService = inject(HttpService);

    getNearest$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(HeremapsNearestActions.getNearestAction),
            concatLatestFrom((action) => this.store.select(selectHeremapsNearestEntryExists(action.value))),
            filter(([, item]) => item === false),
            map(([action]) => HeremapsNearestActions.getNearestInitAction({ value: action.value })),
        );
    });

    getNearestInit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(HeremapsNearestActions.getNearestInitAction),
            mergeMap((action) => {
                if (environment.integrations.hereMaps.v2 === true) {
                    return this.httpService.nearestv2(action.value).pipe(
                        map((item) => HeremapsNearestActions.getNearestSuccessAction({ value: action.value, payload: item })),
                        catchError(() => of(HeremapsNearestActions.getNearestErrorAction({ value: action.value }))),
                    );
                } else {
                    return this.httpService.nearest(action.value).pipe(
                        map((item) => HeremapsNearestActions.getNearestSuccessAction({ value: action.value, payload: item })),
                        catchError(() => of(HeremapsNearestActions.getNearestErrorAction({ value: action.value }))),
                    );
                }
            }),
        );
    });
}
