import { createReducer, on } from '@ngrx/store';
import { countriesActions } from '../actions/countries.actions';
import { Country } from '../models';

export interface CountriesState {
    countries: Country[];
    loaded: boolean;
}

export const initialState: CountriesState = {
    countries: [],
    loaded: false,
};

export const countriesReducer = createReducer(
    initialState,
    on(
        countriesActions.getCountries,
        (state): CountriesState => ({
            ...state,
        }),
    ),
    on(
        countriesActions.getCountriesSuccess,
        (state, action): CountriesState => ({
            ...state,
            loaded: true,
            countries: action.payload,
        }),
    ),
    on(
        countriesActions.getCountriesError,
        (state): CountriesState => ({
            ...state,
        }),
    ),
);
