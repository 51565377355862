import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { ShowSettingsService } from '../../../services';

export function showSettings<T>(): CanDeactivateFn<T> {
    return () => {
        const showSettingsService = inject(ShowSettingsService);
        showSettingsService.setBookingDetailsFlag(true);
        return true;
    };
}
