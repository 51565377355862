import { createReducer, on } from '@ngrx/store';

import { HeremapsGeoreverseActions } from '../actions/georeverse.actions';
import { HereGeocodeModel } from '../models';

export interface HeremapsGeoreverseState {
    data: {
        lat: number;
        lng: number;
        loading: boolean;
        item: HereGeocodeModel | null;
    }[];
}

export const initialState: HeremapsGeoreverseState = {
    data: [],
};

export const heremapsGeoreverseReducer = createReducer(
    initialState,
    on(HeremapsGeoreverseActions.getHeremapsGeoreverseAction, (state, action): HeremapsGeoreverseState => {
        const itemIndex = state.data.findIndex((item) => item.lat === action.lat && item.lng === action.lng);
        if (itemIndex === -1) {
            return {
                ...state,
                data: [
                    ...state.data,
                    {
                        lat: action.lat,
                        lng: action.lng,
                        loading: true,
                        item: null,
                    },
                ],
            };
        }
        return state;
    }),
    on(HeremapsGeoreverseActions.getHeremapsGeoreverseSuccessAction, (state, action): HeremapsGeoreverseState => {
        const data = [...state.data];
        const itemIndex = data.findIndex((item) => item.lat === action.lat && item.lng === action.lng);
        data[itemIndex] = {
            ...data[itemIndex],
            item: action.payload,
            loading: false,
        };
        return { data };
    }),
    on(HeremapsGeoreverseActions.getHeremapsGeoreverseErrorAction, (state, action): HeremapsGeoreverseState => {
        const data = [...state.data];
        const itemIndex = data.findIndex((item) => item.lat === action.lat && item.lng === action.lng);
        data[itemIndex] = {
            ...data[itemIndex],
            loading: false,
        };
        return { data };
    }),
    on(
        HeremapsGeoreverseActions.clearHeremapsGeoreverse,
        (state): HeremapsGeoreverseState => ({
            ...state,
            ...initialState,
        }),
    ),
);
