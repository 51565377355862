import { Pipe, PipeTransform } from '@angular/core';
import { COUNTRY } from '../classes';

@Pipe({
    name: 'country2code',
    standalone: true,
})
export class Country2CodePipe implements PipeTransform {
    transform(value?: string): string {
        if (value) {
            return COUNTRY.con3to2(value);
        }

        return '-';
    }
}
