import { Pipe, PipeTransform } from '@angular/core';
import { Time } from '@app/shared/classes';

@Pipe({
    name: 'day',
    standalone: true,
})
export class DayPipe implements PipeTransform {
    transform(value: string | Date): number | null {
        if (value instanceof Date) {
            return value.getDate();
        }

        if (Time.stringIsDate(value)) {
            return Time.stringToDate(value).getDate();
        }

        return null;
    }
}
