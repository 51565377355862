import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { HereGeocodeModel } from '../models';

export const HeremapsGeoreverseActions = createActionGroup({
    source: 'Heremaps Georeverse',
    events: {
        'Get Heremaps Georeverse Action': props<{ lat: number; lng: number }>(),
        'Get Heremaps Georeverse Success Action': props<{ lat: number; lng: number; payload: HereGeocodeModel }>(),
        'Get Heremaps Georeverse Error Action': props<{ lat: number; lng: number }>(),
        'Clear Heremaps Georeverse': emptyProps(),
    },
});
