import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { selectLoaded, selectCountries, selectCountriesAlpha2, selectCountriesAlpha3 } from '../selectors/countries.selector';
import { countriesActions } from '../actions/countries.actions';

@Injectable({
    providedIn: 'root',
})
export class CountriesFacade {
    private store = inject(Store);

    readonly loaded$ = this.store.select(selectLoaded);
    readonly countries$ = this.store.select(selectCountries);
    readonly alpha2$ = this.store.select(selectCountriesAlpha2);
    readonly alpha3$ = this.store.select(selectCountriesAlpha3);

    getCountries(): void {
        this.store.dispatch(countriesActions.getCountries());
    }
}
