import { CommonModule } from '@angular/common';
import { Component, ElementRef, inject, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { map, Observable, startWith } from 'rxjs';
import { Grow } from '@app/shared/animations';

import { IconComponent } from '../../components/icon/icon.component';
import { FormErrorsComponent } from '../form-errors/form-errors.component';
import { FormDropdownListComponent } from './form-list/form-list.component';
import { FocusService } from './service/focus.service';
import { Item } from './models/item';

@Component({
    selector: 'app-form-dropdown',
    styleUrls: ['./form-dropdown.component.scss'],
    templateUrl: './form-dropdown.component.html',
    standalone: true,
    animations: [Grow],
    providers: [FocusService],
    imports: [CommonModule, ReactiveFormsModule, FormErrorsComponent, IconComponent, FormDropdownListComponent],
})
export class FormDropdownComponent implements OnInit {
    private readonly controlContainer = inject(ControlContainer);
    private readonly focusService = inject(FocusService);
    private readonly renderer2 = inject(Renderer2);

    @ViewChild('focusEl') focusEl!: ElementRef<HTMLDivElement>;
    @ViewChild('blurEl') blurEl!: ElementRef<HTMLDivElement>;

    @Input({ required: true }) controlName!: string;
    @Input({ required: true }) items!: Item[];
    @Input() label?: string;
    @Input() scrollToError = true;

    fc!: FormControl<string | number>;

    readonly focus$ = this.focusService.focus$;

    value$!: Observable<string | number>;

    ngOnInit() {
        this.fc = <FormControl<string>>(<FormGroup>this.controlContainer.control).get(this.controlName);
        this.value$ = this.fc.valueChanges.pipe(
            startWith(this.fc.getRawValue()),
            map((value) => this.items.find((item) => item.value === value)?.label || value),
        );
    }

    onFocusIn() {
        if (this.fc.enabled) {
            this.focusService._focus$.next(true);
            this.renderer2.addClass(this.focusEl.nativeElement, 'active');
        }
    }

    onFocusOut() {
        this.focusService._focus$.next(false);
        this.renderer2.removeClass(this.focusEl.nativeElement, 'active');
        this.fc.markAsTouched();
    }

    onSelectValueEvent(value: string | number) {
        this.fc.patchValue(value);
        this.blurEl.nativeElement.focus();
    }
}
