import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { BehaviorSubject, combineLatest, distinctUntilChanged, filter, timer, map, startWith, Subscription, take, tap } from 'rxjs';
import { Time } from '@app/shared/classes';
import { Grow } from '@app/shared/animations';
import { TickerFacade } from '@app/store/ticker';
import { environment } from '@env/environment';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Message } from '@app/store/ticker/models';

const SKIP_URLS = ['/booking/passenger', '/booking', '/home'];

@Component({
    selector: 'app-ticker',
    templateUrl: './ticker.component.html',
    styleUrls: ['./ticker.component.scss'],
    animations: [Grow],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, TranslateModule, RouterModule],
})
export class TickerComponent implements OnInit, OnDestroy {
    private readonly tickerFacade = inject(TickerFacade);
    private readonly router = inject(Router);
    private hidden$ = new BehaviorSubject<boolean>(true);
    private interval$ = timer(0, environment.ticker.interval.dateCheck * 1000);

    private messagesFiltered$ = combineLatest([this.tickerFacade.messages$.pipe(map((messages) => this.filterMessages(messages))), this.interval$]).pipe(
        map(([messages]) => messages),
        distinctUntilChanged(),
    );

    messages$ = combineLatest([this.hidden$, this.messagesFiltered$]).pipe(map(([hidden, messages]) => (hidden ? [] : messages)));

    private subscriptions$ = new Subscription();

    ngOnInit() {
        this.subscriptions$.add(
            this.router.events
                .pipe(
                    filter((event) => event instanceof NavigationEnd),
                    map((event) => event.url),
                    startWith(this.router.url),
                    filter((url) => !SKIP_URLS.includes(url)),
                    tap(() => this.hidden$.next(false)),
                    take(1),
                )
                .subscribe(),
        );
    }

    ngOnDestroy() {
        this.subscriptions$.unsubscribe();
    }

    private filterMessages(messages: Message[]) {
        const now = Time.now();
        return messages
            .filter((message) => message.active)
            .filter((message) => {
                if (message.activeFrom && Time.isGreater(Time.stringToTime(message.activeFrom), now)) {
                    return false;
                }
                if (message.activeUntil && Time.isGreater(now, Time.stringToTime(message.activeUntil))) {
                    return false;
                }
                return true;
            });
    }
}
