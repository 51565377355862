import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownItem } from '../../models';

@Component({
    selector: 'app-dropdown-list',
    standalone: true,
    imports: [CommonModule, TranslateModule],
    templateUrl: './dropdown-list.component.html',
    styleUrl: './dropdown-list.component.scss',
})
export class DropdownListComponent implements OnDestroy {
    @Input() options: DropdownItem[] = [];
    @Input() type: 'default' | 'language' = 'default';
    @Output() selectEvent = new EventEmitter<string | number>();

    readonly open$ = new BehaviorSubject<boolean>(false);

    private subscriptions$ = new Subscription();

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    getFlagUrl(value: string | null | number): string {
        return `url('assets/svgs-optimized-flags/${(value ?? 'default').toString().toLowerCase().split('-')[0]}.svg')`;
    }

    onSelectClick(value: string | number) {
        this.selectEvent.next(value);
    }
}
