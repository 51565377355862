import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { HereGeocodeModel, NearestValue } from '../models';

export const HeremapsNearestActions = createActionGroup({
    source: 'Heremaps Nearest',
    events: {
        'Get Nearest Action': props<{ value: NearestValue }>(),
        'Get Nearest Init Action': props<{ value: NearestValue }>(),
        'Get Nearest Success Action': props<{
            value: NearestValue;
            payload: HereGeocodeModel;
        }>(),
        'Get Nearest Error Action': props<{ value: NearestValue }>(),
        'Clear Action': emptyProps(),
    },
});
